import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CurafidaSegmentItem } from '../../../../common/entities/curafida-segment.item';
import { SegmentType } from '../../../../common/entities/segment.type';
import { ActionButton, ActionItemType, ActionMenuItem } from '../../../../table/entities/action-menu.item';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    ItemType,
    TableConfig,
    TableUpdateValue,
} from '../../../../table/entities/table';
import { PaginatedResponse, SortBy } from '../../../../common/entities/paginated-response';
import { ExerciseSession, ExerciseSessionState, ExerciseType } from '../../../entities/exerciseSession';
import { RoutingSegment } from '../../../../common/entities/routing-segment';
import { Router } from '@angular/router';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { ExerciseSessionsService } from '../../../services/exercise-sessions';
import { User, UserRoles } from '../../../../auth/entities/user';
import { CurafidaAuthService } from '../../../../auth/services';
import { TherapyXApiService } from '../../../services/therapy-xapi/therapy-x-api.service';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { ModalController } from '@ionic/angular';
import { StyleService } from '../../../../common/services/style/style.service';
import { PatientLearningDetailComponent } from '../patient-learning/patient-learning-detail/patient-learning-detail.component';
import { UserExerciseSessionsService } from '../../../services/user-exercise-sessions';
import { StarProcessingAdapterComponent } from '../../../../table/components/table-adapter/star-processing-adapter.component';
import { format, parseISO } from 'date-fns';

interface LearningListItem extends ExerciseSession {
    actions: ActionMenuItem[];
    dueDate: string | Date;
    startDate: string | Date;
    state: string;
    patientName: string;
    finished_at: Date;
    starValue: {
        value: number;
        max: number;
    };
}

@Component({
    selector: 'lib-learning-overview',
    templateUrl: './learning-overview.component.html',
    styleUrls: ['./learning-overview.component.scss'],
})
export class LearningOverviewComponent implements OnInit {
    isLoading = true;
    isLoadingSuccess = false;
    @Output()
    activeLearningCount$ = new EventEmitter<number>();
    limit = 10;
    learningTableConfig: TableConfig<LearningListItem[]> = new TableConfig<LearningListItem[]>();
    segmentListLearning: CurafidaSegmentItem<SegmentType>[] = [];
    displayedLearningType = SegmentType.ACTIVE;
    finishedLearningStates = [ExerciseSessionState.FINISHED];
    cancelLearningStates = [ExerciseSessionState.CANCELLED, ExerciseSessionState.PATIENT_CANCELLED];
    activeLearningStates = [ExerciseSessionState.ACTIVE];
    plannedLearningStates = [ExerciseSessionState.NOT_PLANNED, ExerciseSessionState.PLANNED];
    loggedInUser: User;
    protected readonly log: Logger;

    constructor(
        private readonly router: Router,
        private readonly exerciseSessionService: ExerciseSessionsService,
        private readonly toastService: ToastService,
        private readonly authService: CurafidaAuthService,
        private readonly therapyXApiService: TherapyXApiService,
        private loggingService: LoggingService,
        private modalCtrl: ModalController,
        private styleService: StyleService,
        private userExerciseSessionsService: UserExerciseSessionsService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    static mapState(state: ExerciseSessionState): string {
        switch (state) {
            case ExerciseSessionState.NOT_PLANNED:
            case ExerciseSessionState.PLANNED:
            case ExerciseSessionState.ACTIVE:
            case ExerciseSessionState.DELAYED:
                return 'OPEN';
            case ExerciseSessionState.PATIENT_CANCELLED:
            case ExerciseSessionState.CANCELLED:
                return 'DECLINED';
            case ExerciseSessionState.NO_SHOW:
                return 'EXPIRED';
            default:
                return state;
        }
    }

    async ngOnInit() {
        this.loggedInUser = this.authService.getSession().user;
        this.initTabs();
        this.initLearningTable();
        await this.setLearningSegmentType(this.displayedLearningType);
    }

    initLearningTable() {
        this.learningTableConfig.emptyListLabel = 'ANY_LEARNING';
        this.learningTableConfig.itemSettings = [
            {
                id: 'startDate',
                prop: 'startDate',
                header: 'Gültig ab',
                type: ItemType.ADAPTER,
                actionType: ActionType.PREVIEW,
                adapter: StringItemAdapterComponent,
                width: '12%',
                columnPosition: 0,
                sortOrderMobile: 1,
                showColNameOnMobile: true,
            },
            {
                id: 'dueDate',
                prop: 'dueDate',
                header: 'Fällig am',
                type: ItemType.ADAPTER,
                actionType: ActionType.PREVIEW,
                adapter: StringItemAdapterComponent,
                width: '12%',
                columnPosition: 0,
                sortOrderMobile: 1,
                showColNameOnMobile: true,
            },
            {
                id: 'responsibleFullName',
                prop: 'responsibleFullName',
                header: 'Patient',
                type: ItemType.ADAPTER,
                actionType: ActionType.PREVIEW,
                adapter: StringItemAdapterComponent,
                width: '18%',
                columnPosition: 2,
            },
            {
                id: 'title',
                prop: 'title',
                header: 'Lerneinheit',
                type: ItemType.ADAPTER,
                actionType: ActionType.PREVIEW,
                adapter: StringItemAdapterComponent,
                width: '32%',
                columnPosition: 3,
                sortOrderMobile: 0,
                isMobileBold: true,
            },
            {
                id: '',
                prop: 'starValue',
                header: 'Fortschritt',
                type: ItemType.ADAPTER,
                adapter: StarProcessingAdapterComponent,
                actionType: ActionType.PREVIEW,
                width: '20%',
                columnPosition: 3,
            },
            {
                prop: '',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                actionType: ActionType.POPOVER,
                fill: 'clear',
                icon: 'ellipsis-vertical',
                id: 'action_open',
                width: '8%',
                columnPosition: 4,
            },
        ];
    }

    initTabs() {
        this.segmentListLearning = [
            new CurafidaSegmentItem({ name: 'Geplant', value: SegmentType.PLANNED }),
            new CurafidaSegmentItem({ name: 'Aktiv', value: SegmentType.ACTIVE }),
            new CurafidaSegmentItem({ name: 'Abgeschlossen', value: SegmentType.INACTIVE }),
            new CurafidaSegmentItem({ name: 'Abgelehnt', value: SegmentType.CANCELLED }),
            new CurafidaSegmentItem({ name: 'Abgelaufen', value: SegmentType.EXPIRED }),
        ];
    }

    async setLearningSegmentType(event: SegmentType) {
        this.displayedLearningType = event;
        this.limit = 10;
        await this.getPatientLearningList({ offset: 0, limit: this.limit });
    }

    async getPatientLearningList(value: TableUpdateValue) {
        this.isLoading = true;
        this.isLoadingSuccess = false;
        this.limit = value.limit;
        try {
            let learningStates;
            let userlearningStates = null;
            if (this.displayedLearningType === SegmentType.ACTIVE) {
                learningStates = this.activeLearningStates;
            } else if (this.displayedLearningType === SegmentType.PLANNED) {
                learningStates = this.plannedLearningStates;
            } else if (this.displayedLearningType === SegmentType.CANCELLED) {
                learningStates = this.cancelLearningStates;
            } else if (this.displayedLearningType === SegmentType.INACTIVE) {
                learningStates = this.finishedLearningStates;
                userlearningStates = this.finishedLearningStates;
            } else if (this.displayedLearningType === SegmentType.EXPIRED) {
                learningStates = null;
                userlearningStates = [ExerciseSessionState.NO_SHOW];
            }
            this.learningTableConfig.list = (await this.exerciseSessionService.getExerciseSessions({
                offset: value.offset,
                limit: this.limit,
                sortBy: value?.sortBy as SortBy,
                sortOrder: value?.sortOrder,
                exerciseType: ExerciseType.LEARNING,
                options: {
                    includeParticipants: true,
                    exerciseSessionStates: learningStates,
                    exerciseSessionUserStates: userlearningStates,
                },
            })) as PaginatedResponse<LearningListItem[]>;

            this.learningTableConfig.list.items = this.setLearningInformation(this.learningTableConfig.list.items);
            if (this.displayedLearningType === SegmentType.ACTIVE) {
                this.activeLearningCount$.emit(this.learningTableConfig.list.total);
            }

            for (const learning of this.learningTableConfig.list.items) {
                learning.actions = [];
                learning.actions.push(
                    new ActionButton(ActionItemType.BUTTON, 'Patientenakte öffnen', ActionType.UPDATE),
                );
            }
            this.isLoadingSuccess = true;
            this.isLoading = false;
        } catch (e) {
            this.log.error('Error in getPatientLearningList', e);
            this.isLoadingSuccess = false;
            this.isLoading = false;
            this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.learningTableConfig.list = new PaginatedResponse<LearningListItem[]>();
        }
    }

    setLearningInformation(learningListItems: LearningListItem[]) {
        for (const learning of learningListItems) {
            learning.state = LearningOverviewComponent.mapState(learning.exerciseSessionState);
            learning.startDate = learning.appointment?.startTime;
            const tableConfigDueDate = this.learningTableConfig.itemSettings.find((tc) => tc.prop === 'dueDate');
            learning.dueDate = learning.appointment?.delayedTime;
            tableConfigDueDate.header = 'Fällig am';
            if (
                learning.exerciseSessionState === ExerciseSessionState.FINISHED &&
                this.displayedLearningType === SegmentType.INACTIVE &&
                learning.stateChanges
            ) {
                const finishedStateChange = learning.stateChanges.find(
                    (sc) => sc.newState === ExerciseSessionState.FINISHED,
                );
                learning.dueDate = finishedStateChange?.created_at ? new Date(finishedStateChange?.created_at) : null;
                learning.finished_at = finishedStateChange?.created_at
                    ? new Date(finishedStateChange?.created_at)
                    : null;
                tableConfigDueDate.header = 'Abgeschlossen am';
            }
            learning.patientName = '-';
            if (learning.participants && learning.participants.length > 0) {
                learning.patientName = `${learning.participants[0]?.user?.lastname}, ${learning.participants[0]?.user?.firstname}`;
            }
            if (learning.responsibleUserRole === UserRoles.PATIENT) {
                learning.responsibleFullName = learning.patientName;
            }
            if (learning.responsible) {
                this.therapyXApiService
                    .getFinishedLessions(learning.responsible, learning.id)
                    .then((finishedLessonsDto) => {
                        learning.starValue = {
                            value: finishedLessonsDto.numFinishedLessons,
                            max: finishedLessonsDto.numTotalLessons,
                        };
                    });
            } else if (learning.participants[0]?.user?.username) {
                this.therapyXApiService
                    .getFinishedLessions(learning.participants[0].user.username, learning.id)
                    .then((finishedLessonsDto) => {
                        learning.starValue = {
                            value: finishedLessonsDto.numFinishedLessons,
                            max: finishedLessonsDto.numTotalLessons,
                        };
                    });
            }
            if (learning.dueDate) {
                learning.dueDate = format(parseISO(learning.dueDate.toString()), 'dd.MM.yyyy \n HH:mm') + ' Uhr';
            }
            if (learning.startDate) {
                learning.startDate = format(parseISO(learning.startDate), 'dd.MM.yyyy \n HH:mm') + ' Uhr';
            }
        }

        return learningListItems;
    }

    async openDetailPage(actionEmitter: ActionEmitter<ExerciseSession>) {
        await this.router.navigate(
            [
                RoutingSegment.MEMBER,
                RoutingSegment.PATIENT_MANAGEMENT,
                RoutingSegment.DETAIL,
                actionEmitter.item.participants[0].user.username,
            ],
            { queryParams: { segment: SegmentType.LEARNING } },
        );
    }

    async openLearningDetailPage(actionEmitter: ActionEmitter<ExerciseSession>) {
        if (actionEmitter.actionType === ActionType.PREVIEW) {
            const learningTask = await this.userExerciseSessionsService.getExerciseSessionOfUser(
                actionEmitter.item.participants[0].user.username,
                Number(actionEmitter.item.id),
            );
            const modal = await this.modalCtrl.create({
                component: PatientLearningDetailComponent,
                cssClass: 'full-width-modal',
                componentProps: {
                    learningResource: learningTask ? learningTask : undefined,
                    isNew: !actionEmitter?.item,
                    isMobile: this.styleService.isMobile$,
                    patient: actionEmitter.item.participants[0].user,
                },
            });
            await modal.present();
            const { data } = await modal.onDidDismiss();
            if (data) {
                await this.getPatientLearningList({ offset: 0, limit: this.limit });
            }
        }
        if (actionEmitter.actionType === ActionType.UPDATE && actionEmitter.item.participants[0]) {
            await this.router.navigate(
                [
                    RoutingSegment.MEMBER,
                    RoutingSegment.PATIENT_MANAGEMENT,
                    RoutingSegment.DETAIL,
                    actionEmitter.item.participants[0].user.username,
                ],
                { queryParams: { segment: SegmentType.LEARNING } },
            );
        }
    }
}
