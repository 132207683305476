import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../../auth/entities/user';
import { CommonComponentsModule } from '../../../../common/components/common-components.module';
import { ICardActionButton } from '../../../../common/entities/card-action-button.interface';
import { ButtonConfig } from '../../../../common/entities/modal/modal-button';
import { ModalConfig } from '../../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../../common/entities/modal/modal-typ';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { LoadingService } from '../../../../common/services/loading/loading.service';
import { ModalAlertService } from '../../../../common/services/modal';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { CurafidaTableComponent } from '../../../../table/components/curafida-table/curafida-table.component';
import { ExerciseType } from '../../../entities/exerciseSession';
import { TherapyState } from '../../../entities/therapy';
import { GenericPlan } from '../../../entities/therapy/generic.plan';
import { CopyTemplateModalComponent } from '../../copy-template-modal/copy-template-modal.component';
import { TaskPlanService } from '../task-plan.service';

@Component({
    selector: 'lib-patient-task-plan-list',
    templateUrl: './patient-task-plan-list.component.html',
    styleUrls: ['./patient-task-plan-list.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule, CurafidaTableComponent],
})
export class PatientTaskPlanListComponent implements OnInit {
    isLoading = true;
    isLoadingSuccess = false;
    @Input()
    user: User;
    @Input()
    hideEditButtons = false;
    taskPlans: GenericPlan[] = [];
    actionButtons: ICardActionButton[] = [];
    protected readonly log: Logger;

    constructor(
        private modalCtrl: ModalController,
        private taskPlanService: TaskPlanService,
        private toastService: ToastService,
        private modalAlertService: ModalAlertService,
        private loggingService: LoggingService,
        private translateService: TranslateService,
        private readonly loadingService: LoadingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit() {
        this.actionButtons.push({
            icon: 'add',
            id: 'add-supervisor',
            isDisabled: false,
            isHidden: false,
            isIconButton: false,
            title: 'TASK.PLAN.ADD',
        });
        this.isLoading = true;
        this.isLoadingSuccess = false;
        this.taskPlanService
            .search({
                username: this.user.username,
                therapyState: TherapyState.RUNNING,
                includeExercises: true,
            })
            .subscribe({
                next: (therapies) => {
                    for (const therapy of therapies.items) {
                        this.taskPlans.push(new GenericPlan(therapy, 'TASK.PLAN.ANY_ASSIGN'));
                    }
                    this.isLoadingSuccess = true;
                },
                error: (message) => {
                    this.log.error('Error in ngOnInit', message);
                    this.toastService.showToast('Fehler beim Abrufen der Pläne.', IonicColor.danger);
                    this.isLoadingSuccess = false;
                },
                complete: () => (this.isLoading = false),
            });
    }

    async addNewPlanTemplate() {
        const addPlan = this.translateService.instant('ADD_TASK_PLAN_SUCCESS_MESSAGE');
        const errorByPlan = this.translateService.instant('TASK.PLAN.FETCH_FAILED');
        const modal = await this.modalCtrl.create({
            component: CopyTemplateModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                patient: this.user,
                exerciseType: ExerciseType.TASK,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.isLoading = true;
            this.isLoadingSuccess = false;
            this.taskPlanService.fetch(data.id).subscribe({
                next: (it) => {
                    this.taskPlans.push(new GenericPlan(it));
                    this.isLoadingSuccess = true;
                },
                error: (message) => {
                    this.log.error(errorByPlan, message);
                    this.toastService.showToast(errorByPlan, IonicColor.danger);
                    this.isLoadingSuccess = false;
                },
                complete: () => (this.isLoading = false),
            });
            this.toastService.showToast(addPlan, IonicColor.success);
        }
    }

    async showRemovePrompt(taskPlan: GenericPlan) {
        const title = 'Aufgabenplan entfernen';
        const description =
            'Durch das Entfernen des Aufgabenplans werden alle zugehörigen noch anstehenden Aufgaben ' +
            'entfernt. Sind Sie sicher, dass Sie fortfahren möchten?';
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = title;
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description = description;

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'Entfernen';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            this.removePlan(taskPlan);
        }
    }

    removePlan(genericPlan: GenericPlan) {
        this.loadingService.startLoadingModal();
        this.taskPlanService.delete(genericPlan.therapyId).subscribe({
            next: () => {
                this.taskPlans = this.taskPlans.filter((tp) => tp.therapyId !== genericPlan.therapyId);
                this.toastService.showToast(
                    this.translateService.instant('TASK.PLAN.REMOVE_SUCCESS_MESSAGE'),
                    IonicColor.success,
                );
            },
            error: (message) => {
                this.log.error('Error in removePlan', message);
                this.toastService.showToast('Fehler beim Löschen des Plans.', IonicColor.danger);
            },
            complete: () => this.loadingService.stopLoadingModal(),
        });
    }
}
