// noinspection ES6UnusedImports

import { TherapyDto } from './therapy-dto';
import { Exercise, ExerciseCatalog } from '../exercise';
import { FrontendTherapySession, TherapySession } from '../therapy-session/therapy-session';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { TherapyTemplate } from './therapy-template';
import { User } from '../../../auth/entities/user';
import { TherapyState } from './therapy-state';

export class Therapy extends TherapyDto {
    id: number;
    templateId: number;
    created_at: Date;
    updated_at: Date;
    isCollapsed = false;
    tags: string[];
    therapyGoal: string;
    finishedExercises: number;
    notes: string;
    therapyTemplate: TherapyTemplate;
    participants: User[];
    parentTherapy: Therapy[];
    childTherapies?: Therapy[];
    exercises: ExerciseCatalog[];
    therapyState: TherapyState;
}

export class FrontendTraining extends Therapy {
    therapySessions: PaginatedResponse<FrontendTherapySession[]>;
    finishedTherapySessions: number;
    runningTherapySessions: number;
    runningExercises: number;
    isLoadingFinished = false;
    isTherapyStillFinished: boolean;
}

export class TherapyWithTherapySessions extends Therapy {
    completeTherapySession: PaginatedResponse<TherapySession[]>;
    runningTherapySession: PaginatedResponse<TherapySession[]>;
    completedTraining?: number;
    plannedTraining?: number;
    latestTrainingDate?: string;
    lastCompletedTraining?: string;
}
