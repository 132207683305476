import { Component, Input, OnInit } from '@angular/core';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { IonicModule, ModalController } from '@ionic/angular';
import { NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ExerciseSessionDto, ExerciseSubType } from '../../../../therapy/entities/exerciseSession';
import { TaskTemplateService } from '../../../../therapy/services/task-template/task-template.service';
import { TaskTemplateResource } from '../../../../therapy/entities/exercise/task-template.resource';
import { TaskTemplateListModalComponent } from '../../../../therapy/components/task/task-templates-list/task-template-list-modal.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CurafidaAuthService } from '../../../../auth/services';
import { User, UserRoles } from '../../../../auth/entities/user';
import { CommonComponentsModule } from '../../common-components.module';
import { MimeTypeCurafida } from '../../../entities/mime.type';
import { QuestionnaireTemplate } from '../../../../my-medax/entities/questionnaire-template';
import { ExercisesService } from '../../../../therapy/services/exercises';
import * as duration from 'duration-fns';
import { TaskService } from '../../../../therapy/services/task/task.service';
import { ToastService } from '../../../services/toast-service/toast-service.service';
import { IonicColor } from '../../../entities/toast/ionic-color';
import { TaskResource } from '../../../../therapy/components/task/task.resource';
import { QuestionnaireType } from '../../../../my-medax/entities/questionnaire-type';
import { TherapyMyMedaxService } from '../../../../therapy/services/therapy-my-medax';
import { BrowserNavigationService } from '../../../services/browser-navigation/browser-navigation.service';
import { LoadingComponent } from '../../loading/loading.component';
import { MyMedaxRedirection } from '../../../../my-medax/my-medax.redirection';

@Component({
    selector: 'upload-form',
    templateUrl: './upload-form.component.html',
    standalone: true,
    imports: [IonicModule, NgIf, TranslateModule, CommonComponentsModule, LoadingComponent],
})
export class UploadFormComponent implements OnInit {
    @Input() patient: User;
    selectedTaskTemplate: TaskTemplateResource;
    taskResource: TaskResource;
    isLoadingFinished = false;
    isButtonDisable = true;
    patientTaskForm: FormGroup;
    userRoles: UserRoles[] = [];
    isEditEnabled = false;
    form: QuestionnaireTemplate;
    hideActions = false;
    loggedInUser: User;
    protected readonly log: Logger;

    constructor(
        private loggingService: LoggingService,
        private modalCtrl: ModalController,
        private taskTemplateService: TaskTemplateService,
        private translate: TranslateService,
        private authService: CurafidaAuthService,
        private formBuilder: FormBuilder,
        private exercisesService: ExercisesService,
        private taskService: TaskService,
        private toastService: ToastService,
        private therapyMyMedaxService: TherapyMyMedaxService,
        private browser: BrowserNavigationService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    ngOnInit(): void {
        this.loggedInUser = this.authService.getSession()?.user;
        this.initComponent();
    }

    async dismissModal(data?: any) {
        this.modalCtrl.dismiss(data);
    }

    async openTaskTemplateModal(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: TaskTemplateListModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                title: this.translate.instant('TASK.TEMPLATE.ITEM.SELECT'),
                searchString: this.translate.instant('TASK.TEMPLATE.ITEM.SEARCH'),
                selectedTaskTemplate: this.selectedTaskTemplate,
                anyItem: 'ANY_HOMEWORK_TEMPLATE',
                hasTags: true,
                hasFilter: true,
                titleColName: this.translate.instant('NAME'),
                exerciseSubType: ExerciseSubType.QUESTIONNAIRE,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.selectedTaskTemplate = await this.taskTemplateService.getTaskTemplateById(data.id);
            this.patientTaskForm.controls.taskTemplateTitle.patchValue(this.selectedTaskTemplate.title);
            this.patientTaskForm.controls.description.patchValue(this.selectedTaskTemplate.description);
            await this.initQuestionnaire();
            this.isButtonDisable = false;
        }
    }

    async savePatientTask(): Promise<void> {
        this.isButtonDisable = true;
        const newPatientTask = new ExerciseSessionDto();
        newPatientTask.exercise_id = this.selectedTaskTemplate.id;
        newPatientTask.exerciseSubType = this.selectedTaskTemplate.exerciseSubType;
        newPatientTask.title = this.selectedTaskTemplate.title;
        newPatientTask.description = this.selectedTaskTemplate.description;
        newPatientTask.duration = duration.toMinutes(duration.parse(this.selectedTaskTemplate?.duration)).toString();
        newPatientTask.responsible = this.loggedInUser.username;
        newPatientTask.needsSchedule = false;
        newPatientTask.autoNoShow = false;
        newPatientTask.notifyOnStartTime = this.selectedTaskTemplate.notifyOnStartTime;
        newPatientTask.notifyOnDelayedTime = this.selectedTaskTemplate.notifyOnDelayedTime;
        newPatientTask.notifyOnEndTime = this.selectedTaskTemplate.notifyOnEndTime;
        newPatientTask.isAutoFinished = this.selectedTaskTemplate.isAutoFinished;
        newPatientTask.concernedUsername = this.patient.username;
        try {
            this.taskResource = await this.taskService.createTask(this.patient.username, newPatientTask);
        } catch (e) {
            this.log.error('Error in savePatientTask', e);
            this.isButtonDisable = false;
            this.toastService.showToast('SAVE_TASK_FAILURE_MESSAGE', IonicColor.danger);
        } finally {
            this.openMyMedax();
            this.toastService.showToast('SAVE_TASK_SUCCESS_MESSAGE', IonicColor.success);
            await this.dismissModal();
        }
    }

    async openMyMedax() {
        const fillLink = await this.therapyMyMedaxService.getMyMedaxFillLink(
            this.patient.username,
            this.taskResource.id.toString(),
            MyMedaxRedirection.forCurrentLocation().withActivationByCapacitor(),
            QuestionnaireType.TASK,
        );
        this.browser.openTargetSelf(fillLink.url);
    }

    private async initComponent() {
        this.userRoles = (await this.authService.getRoles()) as UserRoles[];

        this.isEditEnabled = true;
        this.initTaskForm();
    }

    private async initQuestionnaire() {
        if (!this.selectedTaskTemplate) return;
        if (!this.selectedTaskTemplate.contents) {
            this.selectedTaskTemplate.contents = await this.exercisesService.getContentsFromExercise(
                this.selectedTaskTemplate.id,
            );
        }
        if (
            this.selectedTaskTemplate.contents.length > 0 &&
            this.selectedTaskTemplate.contents[0].mimeType === MimeTypeCurafida.MY_MEDAX
        ) {
            this.form = this.selectedTaskTemplate.contents[0].jsonData as QuestionnaireTemplate;
            if (this.form.isImpermanent) {
                this.hideActions = this.loggedInUser.username !== this.patient.username;
            }
        } else {
            this.form = null;
        }
    }

    private initTaskForm() {
        const description = '';
        let taskTemplateTitle = this.selectedTaskTemplate ? this.selectedTaskTemplate.title : '';

        if (this.selectedTaskTemplate) taskTemplateTitle = this.selectedTaskTemplate.title;
        this.patientTaskForm = this.formBuilder.group({
            taskTemplateTitle: new FormControl(
                {
                    value: taskTemplateTitle,
                    disabled: false,
                },
                Validators.required,
            ),

            description: new FormControl({ value: description, disabled: true }),
        });
        this.isLoadingFinished = true;
    }
}
