import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    ExerciseSessionState,
    ExerciseSessionUserState,
    ExerciseSubType,
    ExerciseType,
} from '../../../entities/exerciseSession';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { User, UserRoles } from '../../../../auth/entities/user';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { UserExerciseSessionsService } from '../../../services/user-exercise-sessions';
import { ModalController, PopoverController } from '@ionic/angular';
import { StyleService } from '../../../../common/services/style/style.service';
import { CurafidaAuthService } from '../../../../auth/services';
import { QuestionnaireTemplate } from '../../../../my-medax/entities/questionnaire-template';
import { TherapyMyMedaxService } from '../../../services/therapy-my-medax';
import { QuestionnaireType } from '../../../../my-medax/entities/questionnaire-type';
import { MyMedaxQuestionnaireSubmissionComponent } from '../../../../my-medax/components/my-medax-questionnaire-submission/my-medax-questionnaire-submission.component';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { endOfDay, isFuture, isPast, startOfDay, subSeconds } from 'date-fns';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActionButton, ActionItemType, ActionMenuItem } from '../../../../table/entities/action-menu.item';
import { CurafidaPopoverSelectionComponent } from '../../../../table/components/curafida-popover-selection/curafida-popover-selection.component';
import { ActionType, DateFormat } from '../../../../table/entities/table';
import { MyMedaxQuestionnaireSubmission } from '../../../../my-medax/entities/my-medax-questionnaire-submission';
import { UserTaskPreview } from '../user-task-preview';
import { ModalAlertService } from '../../../../common/services/modal';
import { TranslateService } from '@ngx-translate/core';
import { QuestionnaireListModalComponent } from '../../../../my-medax/components/questionnaire-list-modal/questionnaire-list-modal.component';
import { PanelStyle } from '../../../../common/entities/panel.style';
import { Content } from '../../../entities/content';
import { TaskActionLinkName, TaskResource } from '../task.resource';
import { AuthorizationPipe } from '../../../../hateoas/authorization.pipe';
import { BrowserNavigationService } from '../../../../common/services/browser-navigation/browser-navigation.service';
import { TaskService } from '../../../services/task/task.service';
import { ArticulateContentsService } from '../../../services/articulate/articulate-contents.service';
import { ContentFormatType } from '../../../../common/entities/content-format-type';
import { ExerciseContentsService } from '../../../services/exercise-contents';
import { LoadingService } from '../../../../common/services/loading/loading.service';
import { Swiper } from 'swiper';
import { register } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { MyMedaxRedirection } from '../../../../my-medax/my-medax.redirection';

register();

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-patient-task-preview',
    templateUrl: './patient-task-preview.component.html',
    styleUrls: ['./patient-task-preview.component.scss'],
})
export class PatientTaskPreviewComponent extends UserTaskPreview implements OnInit, OnDestroy {
    readonly DateFormat = DateFormat;
    taskResource: TaskResource;
    isMobile = false;
    patient: User;
    title = 'Aufgabe';
    ExerciseSessionState = ExerciseSessionState;
    ExerciseSessionUserState = ExerciseSessionUserState;
    form: QuestionnaireTemplate;
    loggedInUser: User;
    myMedaxQuestionnaireSubmissions: MyMedaxQuestionnaireSubmission[];
    userRoles: UserRoles[] = [];
    UserRoles = UserRoles;
    isStartTimeInFuture = false;
    isEndTimeInPast = false;
    showCalendarEvent = false;
    showAppointment = false;
    ExerciseSubType = ExerciseSubType;
    id: string;
    PanelStyle = PanelStyle;
    areContentVisible: boolean;
    hasContent = false;
    protected readonly log: Logger;
    protected readonly ExerciseType = ExerciseType;
    private updateInterval: any;
    @ViewChild('swiper')
    private _swiperRef: ElementRef | undefined;
    swiper?: Swiper;
    options: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 0,
        centerInsufficientSlides: true,
        effect: 'slide',
        speed: 1000,
        setWrapperSize: true,
        autoHeight: false,
        height: 300,
    };
    initSlideFinished = false;
    isBeginning = true;
    isEnd = false;
    isLeftArrowDisabled = true;
    isRightArrowDisabled = false;
    currentIndex = 0;
    Content = Content;
    media: Content[] = [];
    document: Content[] = [];
    isCardCollapsed = false;
    isHeaderClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        protected toastService: ToastService,
        private authService: CurafidaAuthService,
        private styleService: StyleService,
        protected taskService: TaskService,
        private therapyMyMedaxService: TherapyMyMedaxService,
        private modalCtrl: ModalController,
        protected loggingService: LoggingService,
        private popoverController: PopoverController,
        protected modalAlertService: ModalAlertService,
        protected translateService: TranslateService,
        protected authorizationPipe: AuthorizationPipe,
        private browser: BrowserNavigationService,
        protected userExerciseSessionsService: UserExerciseSessionsService,
        readonly articulateContentsService: ArticulateContentsService,
        private readonly loadingService: LoadingService,
        private exerciseContentsService: ExerciseContentsService,
    ) {
        super(
            taskService,
            userExerciseSessionsService,
            toastService,
            modalAlertService,
            loggingService,
            translateService,
            authorizationPipe,
        );
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.isMobile = this.styleService.isMobile$;
    }

    async openMyMedax(): Promise<void> {
        const fillLink = await this.therapyMyMedaxService.getMyMedaxFillLink(
            this.patient.username,
            this.taskResource.id.toString(),
            MyMedaxRedirection.forCurrentLocation().withActivationByCapacitor().leadingToTask(this.taskResource.id),
            QuestionnaireType.TASK,
        );
        this.browser.openTargetSelf(fillLink.url);
    }

    async openContent(content: Content) {
        await this.exerciseContentsService.openAttachmentObjectURLinNewWindow(content, ContentFormatType.ORIGINAL);
    }

    async showSubmissionVersions(): Promise<void> {
        if (this.myMedaxQuestionnaireSubmissions === undefined) {
            this.myMedaxQuestionnaireSubmissions =
                await this.therapyMyMedaxService.fetchMyMedaxQuestionnaireSubmissions(this.taskResource);
        }
        if (this.myMedaxQuestionnaireSubmissions?.length >= 0) {
            const modal = await this.modalCtrl.create({
                component: QuestionnaireListModalComponent,
                backdropDismiss: true,
                cssClass: 'full-width-modal',
                componentProps: {
                    myMedaxQuestionnaireSubmissions: this.myMedaxQuestionnaireSubmissions,
                    questionnaireTitle: this.myMedaxQuestionnaireSubmissions[0].title,
                },
            });
            await modal.present();
            const { data } = await modal.onDidDismiss();
            return data;
        }
    }

    async showQuestionnaireModal(): Promise<void> {
        this.myMedaxQuestionnaireSubmissions = await this.therapyMyMedaxService.fetchMyMedaxQuestionnaireSubmissions(
            this.taskResource,
        );
        const modal = await this.modalCtrl.create({
            component: MyMedaxQuestionnaireSubmissionComponent,
            backdropDismiss: true,
            cssClass: 'full-width-modal',
            componentProps: {
                myMedaxQuestionnaireSubmission: this.myMedaxQuestionnaireSubmissions[0],
                allowFillNewVersion: this.taskResource.exerciseSessionUserState != ExerciseSessionUserState.FINISHED,
                taskResource: this.taskResource,
                showIcons: !this.userRoles.includes(UserRoles.PATIENT),
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        return data;
    }

    async finishTask(): Promise<void> {
        this.loadingService.startLoadingModal();
        try {
            if (this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.PLANNED) {
                await this.taskService.postTaskAction(this.taskResource, TaskActionLinkName.RESPONSIBLE_ACTIVATE);
            }
            await this.taskService.postTaskAction(this.taskResource, TaskActionLinkName.RESPONSIBLE_FINISH);
            this.taskResource.exerciseSessionUserState = ExerciseSessionUserState.FINISHED;
            this.toastService.showToast(this.translateService.instant('FINISH_TASK_SUCCESS'), IonicColor.success);
        } catch (e) {
            this.log.error('Error in finishTask', e);
            this.toastService.showToast(this.translateService.instant('FINISH_TASK_FAILURE'), IonicColor.danger);
        } finally {
            this.loadingService.stopLoadingModal();
            await this.dismissModal();
        }
    }

    async dismissModal(): Promise<void> {
        const queryParams: Params = { fromMyMedax: null, taskId: null };
        await this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams,
            queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
        await this.modalCtrl.dismiss(this.taskResource);
    }

    async openOtherAction(event: MouseEvent): Promise<void> {
        const actionItems: ActionMenuItem[] = [];
        actionItems.push(
            new ActionButton(ActionItemType.BUTTON, this.translateService.instant('CANCEL_TASK'), ActionType.CANCELED),
        );
        const popover = await this.popoverController.create({
            component: CurafidaPopoverSelectionComponent,
            cssClass: 'my-custom-class',
            translucent: true,
            event: event,
            componentProps: {
                actionItems: actionItems,
            },
        });
        await popover.present();
    }

    async ngOnInit(): Promise<void> {
        await this.initComponent();
        if (this.taskResource.exerciseSubType === ExerciseSubType.QUESTIONNAIRE) {
            this.myMedaxQuestionnaireSubmissions =
                await this.therapyMyMedaxService.fetchMyMedaxQuestionnaireSubmissions(this.taskResource);
        }
        if (this.taskResource.exerciseSubType === ExerciseSubType.FREETEXT) await this.initFreeTextContent();
        if (this.taskResource.exerciseSubType === ExerciseSubType.LEARNING) {
            this.articulateContentsService.init(this.taskResource, true);
        }
        this.hasContent = (this.taskResource?.contents || []).some(
            (content) =>
                !(
                    Content.isMyMedaxType(content.mimeType) ||
                    Content.isArticulateMimeType(content.mimeType) ||
                    Content.isArticulateZipMimeType(content.mimeType)
                ),
        );
        if (this.hasContent) {
            this.initSlideFinished = false;
            await this.initializeSlides();
        }
        const swiperEl = this._swiperRef?.nativeElement;
        if (swiperEl) {
            Object.assign(swiperEl, this.options);
            swiperEl.initialize();
        }
    }

    async slideChanged() {
        this.currentIndex = this.swiper.activeIndex;
        this.setSwitch(this.currentIndex);
    }

    async initializeSlides() {
        let i = 0;
        for (const content of this.taskResource?.contents) {
            if (Content.isVideoMimeType(content.mimeType)) {
                content.url = await this.exerciseContentsService.getTempDownloadUrl(content.uuid);
                this.media.push(content);
            } else if (Content.isAudioMimeType(content.mimeType)) {
                content.url = await this.exerciseContentsService.getTempDownloadUrl(content.uuid);
                this.document.push(content);
            } else if (Content.isPDFMimeType(content.mimeType)) {
                let tempBlob = null;
                let tempRetFileData = null;
                //Fetching Data File
                this.exerciseContentsService.getObservableContent(content).subscribe((retFileData: any) => {
                    tempRetFileData = retFileData;
                    tempBlob = new Blob([retFileData], { type: content.mimeType });
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        /* content.pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);*/
                    };
                    fileReader.readAsArrayBuffer(tempBlob);
                });
                this.document.push(content);
            } else if (Content.isTextMimeType(content.mimeType)) {
                let tempBlob = null;
                //Fetching Data File
                this.exerciseContentsService.getObservableContent(content).subscribe((retFileData: any) => {
                    tempBlob = new Blob([retFileData], { type: content.mimeType });
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        /* content.pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);*/
                    };
                    fileReader.readAsArrayBuffer(tempBlob);
                });
                this.document.push(content);
            } else if (Content.isImageMimeType(content.mimeType)) {
                content.url = await this.exerciseContentsService.getObjectURLContentFromUrl(
                    content.uuid,
                    ContentFormatType.MEDIUM_SIZE,
                );
                this.media.push(content);
            } else {
                content.url = await this.exerciseContentsService.getObjectURLContentFromUrl(
                    content.uuid,
                    ContentFormatType.MEDIUM_SIZE,
                );
                this.document.push(content);
            }
            if (i === this.taskResource.contents.length - 1 && this.media.length > 0) {
                this.initSlideFinished = true;
                this.isBeginning = true;
                this.isEnd = false;
                this.setSwitch(0);
                this.swiper = this._swiperRef.nativeElement.swiper;
                if (this.swiper) {
                    this.swiper.on('slideChange', () => {
                        this.slideChanged();
                    });
                }
            }
            i++;
        }
    }

    setSwitch(index: number) {
        if (index === 0 && index === this.taskResource.contents.length - 1) {
            this.isLeftArrowDisabled = true;
            this.isRightArrowDisabled = true;
            this.isBeginning = true;
            this.isEnd = true;
        } else if (index === 0) {
            this.isLeftArrowDisabled = true;
            this.isRightArrowDisabled = false;
            this.isBeginning = true;
            this.isEnd = false;
        } else if (index === this.taskResource.contents.length - 1) {
            this.isEnd = true;
            this.isBeginning = false;
            this.isLeftArrowDisabled = false;
            this.isRightArrowDisabled = true;
        } else {
            this.isBeginning = false;
            this.isEnd = false;
            this.isLeftArrowDisabled = false;
            this.isRightArrowDisabled = false;
        }
    }

    async downloadFile(content: Content) {
        await this.exerciseContentsService.openAttachmentObjectURLinNewWindow(content, ContentFormatType.MEDIUM_SIZE);
    }

    async downloadPDFFile(content: Content) {
        await this.exerciseContentsService.downloadObjectURLinNewWindow(content);
    }

    isExerciseActiveOrFinished() {
        return (
            this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.ACTIVE ||
            (this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.PLANNED &&
                !this.isStartTimeInFuture) ||
            this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.FINISHED
        );
    }

    openArticulateCourse(event: MouseEvent): void {
        event.stopPropagation();
        this.addUpdateInterval();

        const browser = this.articulateContentsService.openArticulateCourseWithIab(
            this.articulateContentsService.url$.getValue().url.toString(),
        );

        browser.on('exit').subscribe(() => {
            this.removeUpdateInterval();
            this.articulateContentsService.updateLessons(this.taskResource);
            this.taskService.fetchTask(Number(this.id)).then((x) => (this.taskResource = x));
        });
    }

    removeUpdateInterval() {
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
        }
    }

    addUpdateInterval() {
        if (!this.updateInterval) {
            this.updateInterval = setInterval(async () => {
                this.articulateContentsService.updateLessons(this.taskResource);
                this.taskResource = await this.taskService.fetchTask(Number(this.id));
            }, 10000);
        }
    }

    ngOnDestroy(): void {
        this.removeUpdateInterval();
    }

    private async initComponent(): Promise<void> {
        this.taskResource = await this.taskService.fetchTask(Number(this.id));
        this.userRoles = this.authService.getSession()?.user.roles;
        if (this.taskResource.calendarEvent) this.showCalendarEvent = true;
        if (this.taskResource.appointment?.startTime || this.taskResource.appointment?.endTime) {
            this.showAppointment = true;
        }
        let startDate = startOfDay(new Date()).toISOString();
        let endDate = endOfDay(subSeconds(new Date(), 1)).toISOString();
        if (this.taskResource.appointment) {
            startDate = this.taskResource.appointment?.startTime;
            endDate = this.taskResource.appointment?.endTime;
        }
        if (startDate) this.isStartTimeInFuture = startDate !== '' ? isFuture(new Date(startDate)) : false;
        if (endDate) this.isEndTimeInPast = endDate !== '' ? isPast(new Date(endDate)) : false;
    }

    collapseCard() {
        this.isCardCollapsed = !this.isCardCollapsed;
        this.isHeaderClick.emit(true);
    }
}
