import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TaskTemplateResource } from '../../../entities/exercise/task-template.resource';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { CheckBoxItemAdapterComponent } from '../../../../table/components/table-adapter/checkbox-item-adapter.component';
import { ItemType, TableConfig, TableItem, TableUpdateValue } from '../../../../table/entities/table';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { PaginatedResponse, SortBy, SortOrder } from '../../../../common/entities/paginated-response';
import { TagsAdapterComponent } from '../../table-adapter/tags-adapter.component';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { Tag } from '../../../entities/tag/tag.entity';
import { ExerciseSubType, ExerciseType } from '../../../entities/exerciseSession';
import { TaskTemplatesSortProperties } from '../../../entities/exercise/task-template.dto';
import { TaskTemplateService } from '../../../services/task-template/task-template.service';
import { FilterModalComponent } from '../../../../common/components/filter-modal/filter-modal.component';
import { TagCategoryView } from '../../../entities/tag/tag-category.entity';
import { TagFilterPipe } from '../../../pipes/tag-filter.pipe';
import { TagsService } from '../../../services/tags';

export interface SelectableTaskTemplateResource extends TaskTemplateResource {
    selected: boolean;
}
@Component({
    selector: 'lib-task-template-list-modal',
    templateUrl: './task-template-list-modal.component.html',
})
export class TaskTemplateListModalComponent implements OnInit {
    @Input()
    createExerciseString = 'Übung erstellen';
    @Input()
    filterExerciseString = 'Filtern';
    @Input()
    searchExerciseString = 'Übungen suchen';
    @Input()
    exerciseSubType: ExerciseSubType;
    isLoading = true;
    isLoadingSuccess = false;

    title: string;
    selectedTaskTemplate: TaskTemplateResource = null;
    exerciseListConfig: TableConfig<SelectableTaskTemplateResource[]> = new TableConfig();
    searchString: string;
    anyItem: string;
    titleColName: string;
    filterTags: Tag[] = [];
    hasFilter = true;
    hasTags = true;
    tableItems: TableItem[];
    private limit = 10;
    newSelectedTaskTemplate: TaskTemplateResource;

    constructor(
        protected modalCtrl: ModalController,
        private taskTemplateService: TaskTemplateService,
        protected toastService: ToastService,
        private tagFilterPipe: TagFilterPipe,
        private tagsService: TagsService,
    ) {
        this.exerciseListConfig.isOpenDetailEnable = true;
        this.exerciseListConfig.hideHeader = false;
    }

    private _searchTerm: string;

    get searchTerm(): string {
        return this._searchTerm;
    }

    set searchTerm(value: string) {
        this._searchTerm = value;
    }

    async ngOnInit() {
        this.exerciseListConfig.emptyListLabel = this.anyItem;
        this.exerciseListConfig.itemSettings = [
            {
                id: 'selected',
                prop: 'selected',
                header: '',
                adapter: CheckBoxItemAdapterComponent,
                type: ItemType.ADAPTER,
                width: '7%',
                columnPosition: 0,
            },
            {
                id: 'title',
                prop: 'title',
                header: this.titleColName,
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '20%',
                sortBy: SortBy.TITLE,
                columnPosition: 1,
            },
            {
                id: 'exerciseSubType',
                prop: 'exerciseSubType',
                header: 'TYPE',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '13%',
                columnPosition: 2,
                translationPrefix: 'TASK.SUB_TYPE.',
            },
            {
                id: 'responsibleUserRole',
                prop: 'responsibleUserRole',
                header: 'ROLE.SINGULAR',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '10%',
                columnPosition: 3,
            },
            {
                id: 'description',
                prop: 'description',
                header: 'Beschreibung',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                sortBy: SortBy.DESCRIPTION,
                columnPosition: 4,
            },
        ];
        if (this.hasTags) {
            this.exerciseListConfig.itemSettings.push({
                id: 'tags',
                prop: 'tags',
                header: 'TAG.PLURAL',
                type: ItemType.ADAPTER,
                adapter: TagsAdapterComponent,
                width: '20%',
                columnPosition: 2,
            });
        }
        if (this.tableItems) this.exerciseListConfig.itemSettings = this.tableItems;
        await this.initTaskTemplateList({ offset: 0 }, this.searchTerm);
    }

    async initTaskTemplateList(value: TableUpdateValue, searchTerm: string) {
        try {
            searchTerm = searchTerm?.trim() ?? '';
            this.isLoading = true;
            this.isLoadingSuccess = false;
            const tagsList = this.filterTags ? this.filterTags.map((tag) => tag.uuid) : [];
            this.exerciseListConfig.list = (await this.taskTemplateService.getTaskTemplates({
                offset: value.offset,
                limit: this.limit,
                textSearchTerm: searchTerm,
                sortOrder: SortOrder.ASC,
                sortBy: TaskTemplatesSortProperties.TITLE,
                tagUuids: tagsList,
                exerciseSubType: this.exerciseSubType,
            })) as PaginatedResponse<SelectableTaskTemplateResource[]>;
            this.isLoading = false;
            this.isLoadingSuccess = true;
            this.exerciseListConfig.list.items = this.exerciseListConfig.list.items.filter(
                (taskTemplateResource) => taskTemplateResource.id != this.selectedTaskTemplate?.id,
            );
            this.exerciseListConfig.list.count = this.exerciseListConfig.list.items.length;
            if (this.selectedTaskTemplate) {
                this.exerciseListConfig.list.total = this.exerciseListConfig.list.total - 1;
            }
            for (const taskTemplateResource of this.exerciseListConfig.list.items) {
                taskTemplateResource.selected = taskTemplateResource.id === this.newSelectedTaskTemplate?.id;
            }
        } catch (err) {
            this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.isLoading = false;
            this.isLoadingSuccess = false;
        }
    }

    async dismissModal() {
        await this.modalCtrl.dismiss();
    }

    async returnSelectedExercise() {
        await this.modalCtrl.dismiss(this.newSelectedTaskTemplate);
    }

    async setFilter() {
        const tagCategoriesView: TagCategoryView[] = (await this.tagsService.getTagCategories(
            ExerciseType.TASK,
        )) as TagCategoryView[];

        const modal = await this.modalCtrl.create({
            component: FilterModalComponent,
            cssClass: 'modal-list',
            componentProps: {
                title: 'Filter',
                selectedTags: this.filterTags,
                tagCategories: tagCategoriesView.map((tag) => this.tagFilterPipe.transform(tag)),
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.filterTags = data;
            await this.initTaskTemplateList({ offset: 0, limit: this.limit }, this.searchTerm);
        }
    }

    onActionOnItem(clickedTaskTemplateResource: SelectableTaskTemplateResource): void {
        clickedTaskTemplateResource.selected = !clickedTaskTemplateResource.selected;
        if (clickedTaskTemplateResource) {
            if (clickedTaskTemplateResource.selected) {
                this.exerciseListConfig.list.items.map((taskTemplateResource) => {
                    if (taskTemplateResource.id === this.newSelectedTaskTemplate?.id) {
                        taskTemplateResource.selected = false;
                    }
                });
                this.newSelectedTaskTemplate = clickedTaskTemplateResource;
            } else {
                this.newSelectedTaskTemplate = null;
            }
        }
    }
}
