import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Content, ContentDto, ContentMetaDataType, ExerciseContentMetaData } from '../../../entities/content';
import { FileItem } from 'ng2-file-upload';
import { ExerciseContentsService } from '../../../services/exercise-contents';
import { ExercisesService } from '../../../services/exercises';
import { Exercise } from '../../../entities/exercise';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { UPLOAD_MIME_TYPE_WHITELIST } from '../../../../common/entities/white-list-mime-type';
import { StyleService } from '../../../../common/services/style/style.service';
import { User, UserRoles } from '../../../../auth/entities/user';
import { CurafidaAuthService } from '../../../../auth/services';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-exercise-training-definition',
    templateUrl: './exercise-content-training-definition.component.html',
    styleUrls: ['./exercise-content-training-definition.component.scss'],
})
export class ExerciseContentTrainingDefinitionComponent implements OnInit {
    @ViewChild('video') videoElement: HTMLVideoElement;
    title = 'Element hinzufügen';
    saveButtonText = 'Speichern';
    exerciseContentForm: FormGroup;
    content: Content;
    isNew = true;
    isNewContentInput = false;
    positionItems = [
        { value: ContentMetaDataType.START, label: 'Ausgangsposition' },
        { value: ContentMetaDataType.MIDDLE, label: 'Übungsausführung' },
        { value: ContentMetaDataType.END, label: 'Endposition' },
        { value: ContentMetaDataType.PLEASE_MIND, label: 'Zu Beachten' },
        { value: ContentMetaDataType.OTHER, label: 'Neues Merkmal' },
        // { value: ContentMetaDataType.THUMBNAIL, label: 'Vorschaubild' },
        { value: ContentMetaDataType.VIDEO, label: 'Video' },
    ];
    ContentMetaDataType = ContentMetaDataType;
    Content = Content;
    progress = 0;
    showProgressBar = false;
    isNewContent = true;
    isLoadingFinished = false;
    whiteListMimeType = UPLOAD_MIME_TYPE_WHITELIST.join();
    isMobile = false;
    loggedInUser: User;
    UserRoles = UserRoles;
    protected readonly log: Logger;
    private isEditEnabled = true;
    private highestOrder = 0;
    private assetInput: FormData;
    private file: FileItem;
    private exercise: Exercise;

    constructor(
        protected modalCtrl: ModalController,
        private formBuilder: FormBuilder,
        protected toastService: ToastService,
        private exerciseContentService: ExerciseContentsService,
        private exercisesService: ExercisesService,
        private params: NavParams,
        private exerciseContentsService: ExerciseContentsService,
        private loggingService: LoggingService,
        private styleService: StyleService,
        private authService: CurafidaAuthService,
    ) {
        this.loggedInUser = authService.getSession().user;
        this.isMobile = this.styleService.isMobile$;
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit() {
        this.isLoadingFinished = false;
        this.content = await this.params.get('content');
        this.exercise = await this.params.get('exercise');
        const highestOrder = await this.params.get('highestOrder');
        this.isNewContent = await this.params.get('isNewContent');
        this.highestOrder = highestOrder ? highestOrder : 0;
        if (this.exercise?.contents && !highestOrder) {
            this.highestOrder = this.exercise?.contents.length;
        }
        if (this.content) {
            this.isNewContentInput = false;
            this.title = this.content.description;
            this.isNew = false;
        } else {
            this.isNew = true;
            this.isNewContentInput = true;
            this.content = new Content();
        }
        const position = ExerciseContentMetaData.isStandardDataType(this.content?.jsonData?.contentMetaDataType)
            ? this.content?.jsonData?.contentMetaDataType
            : ContentMetaDataType.OTHER;
        const description = this.content.description == null ? '' : this.content.description;
        const content = this.content.origFileName == null ? '' : this.content.origFileName;
        this.exerciseContentForm = this.formBuilder.group({
            position: new FormControl({ value: position, disabled: !this.isEditEnabled }, Validators.required),
            description: new FormControl({ value: description, disabled: !this.isEditEnabled }, Validators.required),
            data: new FormControl({ value: content, disabled: !this.isEditEnabled }, Validators.required),
            newPosition: new FormControl(
                {
                    value: this.content?.jsonData?.contentMetaDataType,
                    disabled: !this.isEditEnabled,
                },
                Validators.required,
            ),
        });
        if (!this.loggedInUser.roles.includes(UserRoles.manage_therapy_template)) {
            this.isEditEnabled = false;
            this.exerciseContentForm.get('position').disable();
            this.exerciseContentForm.get('description').disable();
            this.exerciseContentForm.get('content').disable();
            this.exerciseContentForm.get('newPosition').disable();
        }

        this.isLoadingFinished = true;
    }

    async dismissModal() {
        await this.modalCtrl.dismiss();
    }

    createNewExerciseContentMetaData() {
        const order = this.isNew ? this.highestOrder + 1 : this.content.jsonData.order;
        if (this.exerciseContentForm.value.position === ContentMetaDataType.OTHER) {
            return new ExerciseContentMetaData(
                this.exerciseContentForm.value.newPosition,
                order,
                this.exerciseContentForm.value.newPosition,
            );
        } else {
            return new ExerciseContentMetaData(
                this.exerciseContentForm.value.position,
                order,
                this.positionItems.find((item) => item.value === this.exerciseContentForm.value.position).label,
            );
        }
    }

    async saveExerciseContent() {
        try {
            if (!this.isNew) {
                let newContent = await this.exerciseContentService.postContentFileUpload(this.assetInput);
                const contentDto = new ContentDto();
                contentDto.mimeType = newContent.mimeType;
                contentDto.byteSize = newContent.byteSize;
                contentDto.description = this.exerciseContentForm.value.description;
                contentDto.jsonData = this.createNewExerciseContentMetaData();
                newContent = await this.exerciseContentService.updateExerciseContent(newContent.uuid, contentDto);
                await this.exercisesService.assignContentToExercise(this.exercise.id, newContent.uuid);
            } else {
                const contentDto = new ContentDto();
                contentDto.description = this.exerciseContentForm.value.description;
                contentDto.mimeType = '';
                contentDto.jsonData = this.createNewExerciseContentMetaData();
                const newContent = await this.exerciseContentService.createExerciseContent(contentDto);
                await this.exercisesService.assignContentToExercise(this.exercise.id, newContent.uuid);
            }
            this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
        } catch (e) {
            this.log.error(e);
            this.toastService.showToast(ToastService.errorMessageSave, IonicColor.danger);
        }
        await this.modalCtrl.dismiss();
    }

    async setContentInputSelection(content: Content, file: FileItem) {
        this.exerciseContentForm.markAsDirty();
        this.assetInput = new FormData();
        this.file = file;
        this.assetInput.append('file', file.file.rawFile as unknown as File);
        this.exerciseContentForm.controls.data.setValue(file.file.name);
        this.isNewContentInput = true;
    }

    async createNewContent() {
        try {
            const exerciseContentDto = new ContentDto();
            let fileContent: Content;
            if (this.assetInput && !this.isNew) {
                await this.exercisesService.deleteContentFromExercise(this.exercise.id, this.content.uuid);
                await this.exerciseContentService.deleteExerciseContentUuid(this.content.uuid);
            }
            if (this.assetInput) {
                await this.exerciseContentService
                    .createContentFileWithProgressState(this.assetInput)
                    .subscribe((event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.Sent:
                                this.showProgressBar = true;
                                break;
                            case HttpEventType.ResponseHeader:
                                break;
                            case HttpEventType.UploadProgress:
                                this.progress = Math.round((event.loaded / event.total) * 100);
                                break;
                            case HttpEventType.Response:
                                fileContent = event.body;
                                exerciseContentDto.mimeType = fileContent.mimeType;
                                exerciseContentDto.byteSize = fileContent.byteSize;
                                exerciseContentDto.description = this.exerciseContentForm.value.description;
                                exerciseContentDto.jsonData = this.createNewExerciseContentMetaData();
                                this.exerciseContentService.updateExerciseContent(fileContent.uuid, exerciseContentDto);
                                this.exercisesService.assignContentToExercise(this.exercise.id, fileContent.uuid);
                                this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
                                this.showProgressBar = false;
                                this.modalCtrl.dismiss(true);
                        }
                    });
            } else {
                const contentDto = new ContentDto();
                contentDto.mimeType = this.content.mimeType;
                contentDto.byteSize = this.content.byteSize;
                contentDto.description = this.exerciseContentForm.value.description;
                contentDto.jsonData = this.createNewExerciseContentMetaData();
                await Promise.all([
                    this.exerciseContentService.updateExerciseContent(this.content.uuid, contentDto),
                    this.exercisesService.assignContentToExercise(this.exercise.id, this.content.uuid),
                ]);
                this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
                await this.modalCtrl.dismiss(true);
            }
        } catch (e) {
            this.toastService.showToast(ToastService.errorMessageSave, IonicColor.danger);
            await this.modalCtrl.dismiss(true);
        }
    }

    positionOnChange(event: CustomEvent) {
        if (event.detail.value === ContentMetaDataType.OTHER) {
            this.exerciseContentForm.controls.newPosition.setValidators(Validators.required);
            this.exerciseContentForm.controls.newPosition.updateValueAndValidity();
        } else {
            this.exerciseContentForm.controls.newPosition.removeValidators(Validators.required);
            this.exerciseContentForm.controls.newPosition.updateValueAndValidity();
        }
    }

    async downloadFile(content: Content) {
        await this.exerciseContentsService.openAttachmentObjectURLinNewWindow(content);
    }
}
