// noinspection ES6UnusedImports

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ExerciseContentsService } from '../../services/exercise-contents';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { Content, ContentDto, ContentMetaDataType, ExerciseContentMetaData } from '../../entities/content';
import { Exercise } from '../../entities/exercise';
import { ContentFormatType } from '../../../common/entities/content-format-type';
import { ExercisesService } from '../../services/exercises';
import { UPLOAD_IMAGE_MIME_TYPE_WHITELIST } from '../../../common/entities/white-list-image-mime-type';
import { ContentSparseResource } from '../../../meeting/resources/meeting-template.resource';

@Component({
    selector: 'curafida-thumbnail',
    templateUrl: './curafida-thumbnail.component.html',
    styleUrls: ['./curafida-thumbnail.component.scss'],
})
export class CurafidaThumbnailComponent implements OnInit {
    mimeTypeAccepted = UPLOAD_IMAGE_MIME_TYPE_WHITELIST.join();
    @Input() content: Content;
    @Input() isEditEnabled: boolean;
    @Input() onDetails: boolean = false;
    @Input() contents: (Content | ContentSparseResource)[];
    @Input() exerciseId: number;
    uploadFile: EventEmitter<FileItem> = new EventEmitter<FileItem>();
    uploader: FileUploader;
    @ViewChild('fileUploader', { static: true }) fileUploader: ElementRef;
    informationTooltip =
        'Tipp: Als Bildgröße ist 960x540 px bzw. ein Seitenverhältnis von 16:9 optimal  für die Darstellung';
    Content = Content;
    @Input() label: string;
    @Input() isMobile: boolean;
    @Output() toggleForm: EventEmitter<boolean> = new EventEmitter<boolean>();
    buttonLabel: string;
    protected readonly log: Logger;
    private isNewContent = false;

    constructor(
        protected exerciseContentService: ExerciseContentsService,
        protected modalCtrl: ModalController,
        protected toastService: ToastService,
        private loggingService: LoggingService,
        private exercisesService: ExercisesService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async initThumbnail(fileContent?: Content) {
        if (fileContent) this.content = fileContent;
        if (this.contents) {
            this.content = this.contents.find(
                (i) => i.jsonData?.contentMetaDataType === ContentMetaDataType.THUMBNAIL,
            ) as Content;
        }
        if (this.content?.uuid) {
            this.content.url = await this.exerciseContentService.getObjectURLContentFromUrl(
                this.content.uuid,
                ContentFormatType.MEDIUM_SIZE,
            );
            this.buttonLabel = 'Vorschaubild ändern';
        } else {
            this.isNewContent = true;
            this.buttonLabel = 'Vorschaubild hinzufügen';
        }
    }

    openUploadWindows() {
        this.uploader = new FileUploader({
            url: '',
            allowedMimeType: this.mimeTypeAccepted.split(','),
            queueLimit: 1,
            // maxFileSize: 20 * 1024 * 1024
        });
        this.uploader.clearQueue();
        this.fileUploader.nativeElement.click();
        this.uploader.onAfterAddingAll = async (files) => {
            for (const file of files) {
                await this.createThumbnailContent(file);
            }
            this.fileUploader.nativeElement.value = '';
            this.uploader.clearQueue();
        };
        this.uploader.onWhenAddingFileFailed = async (item, filter) => {
            switch (filter.name) {
                // The size filter first has to be enabled in the FileUploader options
                case 'fileSize':
                    this.toastService.showToast(
                        `Die Datei ${item.name} ist zu groß. Maximale Dateigröße 20 MB.`,
                        IonicColor.danger,
                    );
                    break;
                case 'mimeType':
                    this.toastService.showToast(
                        `Das Format der Datei ${item.name} wird nicht unterstützt.`,
                        IonicColor.danger,
                    );
                    break;
                // The queue limit first has to be enabled in the FileUploader options
                case 'queueLimit':
                    this.toastService.showToast(
                        `Datei ${item.name} wurde nicht hochgeladen, es kann nur eine Datei gleichzeitig hochgeladen werden`,
                        IonicColor.danger,
                    );
                    break;
                default:
                    this.log.error('Error in openUploadWindows', `Unknown error (filter is ${filter.name})`);
                    this.toastService.showToast(
                        `Beim Hochladen der Datei ${item.name} ist ein Fehler aufgetreten.`,
                        IonicColor.danger,
                    );
                    break;
            }
        };
    }

    async createThumbnailContent(file: FileItem) {
        try {
            const exerciseContentDto = new ContentDto();
            if (!this.isNewContent) {
                await this.exercisesService.deleteContentFromExercise(this.exerciseId, this.content.uuid);
                await this.exerciseContentService.deleteExerciseContentUuid(this.content.uuid);
            }

            const assetInput = new FormData();
            assetInput.append('file', file.file.rawFile as File);
            const fileContent = await this.exerciseContentService.postContentFileUpload(assetInput);
            exerciseContentDto.mimeType = fileContent.mimeType;
            exerciseContentDto.byteSize = fileContent.byteSize;
            exerciseContentDto.description = '';
            exerciseContentDto.jsonData = new ExerciseContentMetaData(ContentMetaDataType.THUMBNAIL, 0, '');
            await this.exerciseContentService.updateExerciseContent(fileContent.uuid, exerciseContentDto);
            await this.exercisesService.assignContentToExercise(this.exerciseId, fileContent.uuid);
            this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
            await this.exerciseContentService.getExerciseContentUuid(fileContent.uuid);
            if (!this.content) this.content = fileContent;
            this.content.url = await this.exerciseContentService.getObjectURLContentFromUrl(
                fileContent.uuid,
                ContentFormatType.MEDIUM_SIZE,
            );
        } catch (e) {
            this.toastService.showToast(ToastService.errorMessageSave, IonicColor.danger);
            await this.modalCtrl.dismiss(true);
        } finally {
            this.toggleForm.emit();
        }
    }

    ngOnInit(): void {
        this.initThumbnail();
    }
}
