import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SelectableItem } from '../../../../common/components/checkbox-list-modal/checkbox-list-modal.component';
import { ItemType, TableConfig } from '../../../../table/entities/table';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { CheckBoxItemAdapterComponent } from '../../../../table/components/table-adapter/checkbox-item-adapter.component';
import { PaginatedResponse } from '../../../../common/entities/paginated-response';

@Component({
    selector: 'lib-task-preconditions-modal',
    templateUrl: './task-preconditions-config-modal.component.html',
    styleUrls: ['./task-preconditions-config-modal.component.scss'],
})
export class TaskPreconditionsConfigModalComponent implements OnInit {
    readonly listConfig: TableConfig<SelectableItem[]> = new TableConfig<SelectableItem[]>();

    constructor(protected modalCtrl: ModalController) {}

    @Input()
    set preconditionOptions(options: SelectableItem[]) {
        this.listConfig.list = PaginatedResponse.init(options);
    }

    ngOnInit() {
        this.listConfig.isOpenDetailEnable = true;
        this.listConfig.itemSettings = [
            {
                id: `selected`,
                prop: 'selected',
                header: '',
                type: ItemType.ADAPTER,
                adapter: CheckBoxItemAdapterComponent,
                width: '10%',
                sortOrderMobile: 0,
                columnPosition: 0,
            },
            {
                id: `title`,
                prop: 'label',
                header: 'NAME',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '45%',
                maxTextLines: 1,
                sortOrderMobile: 1,
                columnPosition: 1,
                isMobileBold: true,
            },
            {
                id: `description`,
                prop: 'description',
                header: 'DESCRIPTION',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '45%',
                maxTextLines: 1,
                sortOrderMobile: 2,
                columnPosition: 2,
                isMobileBold: true,
            },
        ];
    }

    cancel() {
        return this.modalCtrl.dismiss();
    }

    confirm() {
        const selectedItems = this.listConfig.list.items.filter((it) => it.selected);
        return this.modalCtrl.dismiss(selectedItems);
    }
}
