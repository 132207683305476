import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../../auth/entities/user';
import { CommonComponentsModule } from '../../../../common/components/common-components.module';
import { ICardActionButton } from '../../../../common/entities/card-action-button.interface';
import { ButtonConfig } from '../../../../common/entities/modal/modal-button';
import { ModalConfig } from '../../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../../common/entities/modal/modal-typ';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { ModalAlertService } from '../../../../common/services/modal';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { CurafidaTableComponent } from '../../../../table/components/curafida-table/curafida-table.component';
import { LoadingProcess, LoadingStates } from '../../../../table/components/curafida-table/loading.process';
import { ExerciseType } from '../../../entities/exerciseSession';
import { TherapyState } from '../../../entities/therapy';
import { GenericPlan } from '../../../entities/therapy/generic.plan';
import { TherapiesService } from '../../../services/therapies';
import { UsersTherapiesService } from '../../../services/users-therapies';
import { CopyTemplateModalComponent } from '../../copy-template-modal/copy-template-modal.component';

/**
 * @Deprecated
 * TODO: Delete with learning module (Learning is subtype of task now). Use PatientTaskPlanList instead.
 */
@Component({
    selector: 'lib-patient-plan-list',
    templateUrl: './patient-plan-list.component.html',
    styleUrls: ['./patient-plan-list.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule, CurafidaTableComponent],
})
export class PatientPlanListComponent implements OnInit {
    cardTitleLabel: string;
    isLoading: LoadingStates;
    @Input()
    user: User;
    @Input()
    type: ExerciseType;
    @Input()
    hideEditButtons = false;
    taskPlans: GenericPlan[] = [];
    actionButtons: ICardActionButton[] = [];
    protected readonly log: Logger;

    constructor(
        private modalCtrl: ModalController,
        private therapiesService: TherapiesService,
        private userTherapiesService: UsersTherapiesService,
        private toastService: ToastService,
        private modalAlertService: ModalAlertService,
        private loggingService: LoggingService,
        private translateService: TranslateService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit() {
        this.initStrings();
        this.isLoading = LoadingProcess.initLoadingProcess();
        try {
            const therapies = await this.userTherapiesService.getUserTherapies({
                username: this.user.username,
                therapyState: TherapyState.RUNNING,
                exerciseType: this.type,
            });
            for (const therapy of therapies.items) {
                this.taskPlans.push(new GenericPlan(therapy, this.type + '.PLAN.ANY_ASSIGN'));
            }
            this.isLoading = LoadingProcess.finishedSuccessfullyLoadingProcess();
        } catch (e) {
            this.log.error('Error in ngOnInit', e);
            this.toastService.showToast('Fehler beim Abrufen der Pläne.', IonicColor.danger);
            this.isLoading = LoadingProcess.finishedWithErrorLoadingProcess();
        }
    }

    initStrings() {
        this.cardTitleLabel = this.type + '.PLAN.PLURAL';
        this.actionButtons.push({
            icon: 'add',
            id: 'add-supervisor',
            isDisabled: false,
            isHidden: false,
            isIconButton: false,
            title: this.type + '.PLAN.ADD',
        });
    }

    async addNewPlanTemplate() {
        this.isLoading = LoadingProcess.initLoadingProcess();

        const modal = await this.modalCtrl.create({
            component: CopyTemplateModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                patient: this.user,
                exerciseType: this.type,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            try {
                const therapy = await this.therapiesService.getTherapyId(data.id);
                this.taskPlans.push(new GenericPlan(therapy));
                this.isLoading = LoadingProcess.finishedSuccessfullyLoadingProcess();
            } catch (e) {
                this.log.error(this.type + '.PLAN.FETCH_FAILED', e);
                this.toastService.showToast(this.type + '.PLAN.FETCH_FAILED', IonicColor.danger);
                this.isLoading = LoadingProcess.finishedWithErrorLoadingProcess();
            }
        } else {
            this.isLoading = LoadingProcess.finishedSuccessfullyLoadingProcess();
        }
    }

    async showRemovePrompt(taskPlan: GenericPlan) {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = this.type + '.PLAN.REMOVE';
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description = this.type + '.PLAN.REMOVE_EXPLANATION';

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'REMOVE';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            await this.removePlan(taskPlan);
        }
    }

    async removePlan(genericPlan: GenericPlan) {
        try {
            await this.therapiesService.deleteTherapy(genericPlan.therapyId);
            this.taskPlans = this.taskPlans.filter((tp) => tp.therapyId !== genericPlan.therapyId);
            this.toastService.showToast(
                this.translateService.instant(this.type + '.PLAN.REMOVE_SUCCESS_MESSAGE'),
                IonicColor.success,
            );
        } catch (e) {
            this.log.error('Error in removePlan', e);
            this.toastService.showToast('Fehler beim Löschen des Plans.', IonicColor.danger);
        }
    }
}
