import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Exercise, ExerciseCatalog } from '../../../entities/exercise';
import { ItemType, TableConfig, TableItem, TableUpdateValue } from '../../../../table/entities/table';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { ExercisesService } from '../../../services/exercises';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { ExerciseType } from '../../../entities/exerciseSession';
import { SortBy, SortOrder } from '../../../../common/entities/paginated-response';
import { Tag } from '../../../entities/tag/tag.entity';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { CheckBoxItemAdapterComponent } from '../../../../table/components/table-adapter/checkbox-item-adapter.component';
import { TagsAdapterComponent } from '../../table-adapter/tags-adapter.component';
import { CommonComponentsModule } from '../../../../common/components/common-components.module';
import { TableModule } from '../../../../table/table.module';
import { CurafidaTableComponent } from '../../../../table/components/curafida-table/curafida-table.component';
import { LoadingProcess, LoadingStates } from '../../../../table/components/curafida-table/loading.process';
import { FilterModalComponent } from '../../../../common/components/filter-modal/filter-modal.component';
import { TagCategoryView } from '../../../entities/tag/tag-category.entity';
import { TagsService } from '../../../services/tags';
import { TagFilterPipe } from '../../../pipes/tag-filter.pipe';
import { ToolbarModalComponent } from '../../../../common/components/toolbar-modal/toolbar-modal.component';

@Component({
    selector: 'lib-exercise-list-modal',
    templateUrl: './exercise-list-modal.component.html',
    styleUrls: ['./exercise-list-modal.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule, TableModule, CurafidaTableComponent, ToolbarModalComponent],
})
export class ExerciseListModalComponent implements OnInit {
    @Input()
    createExerciseString = 'Übung erstellen';
    @Input()
    filterExerciseString = 'Filtern';
    isLoading: LoadingStates;

    title: string;
    selectedExercises: Exercise[] = [];
    exerciseListConfig: TableConfig<Exercise[]> = new TableConfig();
    isMultipleChoice = false;
    exerciseType: ExerciseType;
    searchString: string;
    anyItem: string;
    filterTags: Tag[] = [];
    hasFilter = true;
    hasTags = true;
    tableItems: TableItem[];
    private limit = 10;
    showOldSelectedExercise = false;
    addToSelectedExercises: Exercise[] = [];

    constructor(
        private modalCtrl: ModalController,
        private exercisesService: ExercisesService,
        private toastService: ToastService,
        private tagsService: TagsService,
        private tagFilterPipe: TagFilterPipe,
    ) {
        this.exerciseListConfig.isOpenDetailEnable = true;
        this.exerciseListConfig.hideHeader = false;
    }

    private _searchTerm: string;

    get searchTerm(): string {
        return this._searchTerm;
    }

    set searchTerm(value: string) {
        this._searchTerm = value;
    }

    initExerciseListConfig() {
        this.exerciseListConfig.emptyListLabel = this.anyItem;
        if (this.exerciseType === ExerciseType.TASK) {
            this.exerciseListConfig.itemSettings = [
                {
                    id: 'selected',
                    prop: 'selected',
                    header: '',
                    adapter: CheckBoxItemAdapterComponent,
                    type: ItemType.ADAPTER,
                    width: '7%',
                    columnPosition: 0,
                },
                {
                    id: 'title',
                    prop: 'title',
                    header: 'NAME',
                    type: ItemType.ADAPTER,
                    adapter: StringItemAdapterComponent,
                    width: '20%',
                    sortBy: SortBy.TITLE,
                    columnPosition: 1,
                },
                {
                    id: 'exerciseSubType',
                    prop: 'exerciseSubType',
                    header: 'TYPE',
                    type: ItemType.ADAPTER,
                    adapter: StringItemAdapterComponent,
                    width: '13%',
                    sortBy: SortBy.DESCRIPTION,
                    columnPosition: 2,
                },
                {
                    id: 'responsibleUserRole',
                    prop: 'responsibleUserRole',
                    header: 'ROLE.SINGULAR',
                    type: ItemType.ADAPTER,
                    adapter: StringItemAdapterComponent,
                    width: '10%',
                    columnPosition: 3,
                },
                {
                    id: 'description',
                    prop: 'description',
                    header: 'Beschreibung',
                    type: ItemType.ADAPTER,
                    adapter: StringItemAdapterComponent,
                    width: '30%',
                    sortBy: SortBy.DESCRIPTION,
                    columnPosition: 4,
                },
            ];
        } else {
            this.exerciseListConfig.itemSettings = [
                {
                    id: 'selected',
                    prop: 'selected',
                    header: '',
                    adapter: CheckBoxItemAdapterComponent,
                    type: ItemType.ADAPTER,
                    width: '10%',
                    columnPosition: 0,
                },
                {
                    id: 'title',
                    prop: 'title',
                    header: 'NAME',
                    type: ItemType.ADAPTER,
                    adapter: StringItemAdapterComponent,
                    width: '30%',
                    sortBy: SortBy.TITLE,
                    columnPosition: 1,
                },
                {
                    id: 'description',
                    prop: 'description',
                    header: 'DESCRIPTION',
                    type: ItemType.ADAPTER,
                    adapter: StringItemAdapterComponent,
                    width: '60%',
                    sortBy: SortBy.DESCRIPTION,
                    columnPosition: 2,
                },
            ];
        }

        if (this.hasTags) {
            this.exerciseListConfig.itemSettings.push({
                id: 'categoryId',
                prop: 'tagsArray',
                header: 'TAG.PLURAL',
                type: ItemType.ADAPTER,
                adapter: TagsAdapterComponent,
                width: '20%',
                columnPosition: 2,
            });
        }
        if (this.tableItems) this.exerciseListConfig.itemSettings = this.tableItems;
        if (!this.isMultipleChoice && this.selectedExercises.length > 1) {
            throw new Error(
                'If the single choice option is set, there cannot be more than one selected exercise as an input.',
            );
        }
    }

    async ngOnInit() {
        this.initExerciseListConfig();
        await this.initExerciseList({ offset: 0 }, this.searchTerm);
    }

    async initExerciseList(value: TableUpdateValue, searchTerm: string) {
        try {
            this.isLoading = LoadingProcess.initLoadingProcess();
            const tagsList = this.filterTags ? this.filterTags.map((tag) => tag.uuid) : [];
            this.exerciseListConfig.list = await this.exercisesService.getExercises(
                value.offset,
                this.limit,
                searchTerm,
                SortOrder.ASC,
                SortBy.TITLE,
                this.exerciseType,
                false,
                false,
                true,
                tagsList,
                this.showOldSelectedExercise
                    ? []
                    : this.selectedExercises?.map((selectedExercise) => selectedExercise.id.toString()),
            );
            const localExerciseList: ExerciseCatalog[] = [];
            for (const exerciseMapped of this.exerciseListConfig.list.items as ExerciseCatalog[]) {
                exerciseMapped.tagsArray = exerciseMapped.tags;
                localExerciseList.push(exerciseMapped);
            }

            this.exerciseListConfig.list.items = localExerciseList;
            this.exerciseListConfig.list.limit = this.limit;
            this.exerciseListConfig.list.count = this.limit;
            this.isLoading = LoadingProcess.finishedSuccessfullyLoadingProcess();
            // Adds the selected property to selected entries after new search
            this.exerciseListConfig.list.items.map((value) => {
                const match = this.addToSelectedExercises?.find((item) => item.id === value.id);
                if (match) {
                    value.selected = true;
                }
            });
        } catch (err) {
            this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.isLoading = LoadingProcess.finishedWithErrorLoadingProcess();
        }
    }

    onActionOnItem(clickedExercise: Exercise) {
        clickedExercise.selected = !clickedExercise.selected;
        if (clickedExercise) {
            if (clickedExercise.selected) {
                if (!this.isMultipleChoice) {
                    this.addToSelectedExercises = [clickedExercise];
                    for (const exercise of this.exerciseListConfig.list.items) {
                        if (clickedExercise.id !== exercise.id) exercise.selected = false;
                    }
                } else {
                    this.addToSelectedExercises.push(clickedExercise);
                }
            } else {
                if (!this.isMultipleChoice) {
                    this.addToSelectedExercises = [];
                } else {
                    this.addToSelectedExercises = this.addToSelectedExercises.filter(
                        (e) => e.id !== clickedExercise.id,
                    );
                }
            }
        }
    }

    async returnSelectedExercise() {
        await this.modalCtrl.dismiss(this.addToSelectedExercises);
    }

    async setFilter() {
        const tagCategoriesView: TagCategoryView[] = (await this.tagsService.getTagCategories(
            this.exerciseType,
        )) as TagCategoryView[];

        const modal = await this.modalCtrl.create({
            component: FilterModalComponent,
            cssClass: 'modal-list',
            componentProps: {
                title: 'Filter',
                selectedTags: this.filterTags,
                tagCategories: tagCategoriesView.map((tag) => this.tagFilterPipe.transform(tag)),
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.filterTags = data;
            await this.initExerciseList({ offset: 0, limit: this.limit }, this.searchTerm);
        }
    }
}
