// noinspection ES6UnusedImports

import { Tag } from '../tag/tag.entity';
import { ExerciseSessionState } from '../exerciseSession';
import { PhysicalExerciseGoalDto } from '../exerciseGoal/exerciseGoalDto';
import { Content } from '../content';
import { Exercise } from '../exercise';
import { SelectInputItem } from '../../../common/components/curafida-input/curafida-select-input/curafida-select-input.component';

export class TrainingDefinition {
    title: string;
    tags: Tag[];
    exercises: TrainingExercise[];
}

export class TrainingExercise extends Exercise {
    id: number;
    exerciseSessionId: number;
    title: string;
    description: string;
    order: number;
    state: ExerciseSessionState;
    contents: TrainingContent[];
    exerciseGoal: PhysicalExerciseGoalDto;
}

export class TrainingContent extends Content {
    name: string;
    order: number;
    pdfSrc: any;
}

export enum TrainingUsingTyp {
    CONCEPT,
    PATIENT,
    CAREGIVER,
}

export const TrainingRestDaysList = [
    { value: 0, label: 0 },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
];

export const TrainingPerWeekList = [...Array(8).keys()].map((x) => {
    return { value: x, label: x };
});
