export enum MimeTypeCurafida {
    PNG = 'image/png',

    /**
     * Do not use this MIME type!
     * Use MimeTypeCurafida.JPEG = 'image/jpeg' instead.
     * Contrary to what you might think, this is not a standardized mime type.
     */
    JPG = 'image/jpg',

    /**
     * This is the MIME type for JPEG images with both *.jpeg and *.jpg file extension.
     */
    JPEG = 'image/jpeg',

    GIF = 'image/gif',
    TIFF = 'image/tiff',
    PDF = 'application/pdf',
    FIHR_JSON = 'application/fhir+json',
    MY_MEDAX = 'application/vnd.my-medax-questionnaire-template+json',
    CURAFIDA_QUESTIONNAIRE = 'application/vnd.curafida-fhir-questionnaire+json',
    TWINTRAC_ECG = 'application/vnd.twintrac-ecg-3chan-raw+octet-stream',
    MULTI_ECG = 'application/vnd.multi-ecg-12chan-raw+octet-stream',
    MPEG_AUDIO = 'audio/m4a',
    AAC_AUDIO = 'audio/aac',
    ZIP = 'application/zip',
    CSV = 'text/csv',

    /**
     * The backend does not allow uploading application/octet-stream data.
     * This MIME type is like the type `any` in TypeScript.
     * It just says this is arbitrary binary data which type I don't know.
     * @remarks
     * avoid using it
     */
    RAW_DATA = 'application/octet-stream',
}
