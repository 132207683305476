import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FileUploader } from 'ng2-file-upload';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    DateFormat,
    ItemType,
    TableConfig,
    TableUpdateValue,
} from '../../../../table/entities/table';
import { Content, ContentDto, ContentMetaDataType } from '../../../entities/content';
import { ExerciseContentsService } from '../../../services/exercise-contents';
import { ExercisesService } from '../../../services/exercises';
import { ContentsListCheckboxComponent } from '../../checkbox-list';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { ActivatedRoute } from '@angular/router';
import { PaginatedResponse, SortBy } from '../../../../common/entities/paginated-response';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { UPLOAD_MIME_TYPE_WHITELIST } from '../../../../common/entities/white-list-mime-type';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { UserRoles } from '../../../../auth/entities/user';
import { ContentFormatType } from '../../../../common/entities/content-format-type';
import { ICardActionButton } from '../../../../common/entities/card-action-button.interface';
import { ContentSparseResource } from '../../../../meeting/resources/meeting-template.resource';
import { Exercise } from '../../../entities/exercise';
import { Validators } from '@angular/forms';
import { defaultInputValidator } from '../../../../common/validators/curafida-validators';

//TODO: change process when the creation of training exercise with orm and cascade works
@Component({
    selector: 'lib-exercise-content-card',
    templateUrl: './exercise-contents.component.html',
    styleUrls: ['./exercise-contents.component.scss'],
})
export class ExerciseContentsComponent implements OnInit {
    @ViewChild('fileUploader', { static: true }) fileUploader: ElementRef;
    @Input()
    exercise: Exercise = new Exercise();
    @Input()
    refreshTable = true;
    @Input()
    hasRightToModified = false;
    @Input()
    contents: (Content | ContentSparseResource)[];
    @Input()
    exerciseId: number;
    index = 0;
    showLoadingContent = false;
    contentsListConfig: TableConfig<(Content | ContentSparseResource)[]> = new TableConfig();
    contentOfNewExercise: (Content | ContentSparseResource)[] = [];
    public uploader: FileUploader = new FileUploader({
        url: '',
        allowedMimeType: UPLOAD_MIME_TYPE_WHITELIST,
        // queueLimit: 1,
        maxFileSize: 20 * 1024 * 1024,
    });

    exerciseType: string;
    durationUnit: string;

    validators: Validators = Validators.compose([defaultInputValidator, Validators.minLength(1)]);
    whiteListMimeType = UPLOAD_MIME_TYPE_WHITELIST.join();
    UserRoles = UserRoles;
    actionButtons: ICardActionButton[] = [];
    protected readonly log: Logger;

    constructor(
        private modalController: ModalController,
        private exerciseContentService: ExerciseContentsService,
        protected toastService: ToastService,
        private exercisesService: ExercisesService,
        public route: ActivatedRoute,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.actionButtons.push({
            icon: 'add',
            id: 'add-supervisor',
            isDisabled: false,
            isHidden: false,
            isIconButton: false,
            title: 'TRAINING.CONTENT.ADD_ITEM',
        });
    }

    ngOnInit(): void {
        this.initContentsTable();
    }

    async initContentsTable() {
        this.refreshTable = false;
        this.contentsListConfig.list = PaginatedResponse.init<Content>([]);
        if (this.contents?.length > 0) {
            this.contentsListConfig.list = PaginatedResponse.init(
                this.contents
                    ?.filter((i) => !i?.jsonData?.contentMetaDataType)
                    .sort((a, b) => a.origFileName.localeCompare(b.origFileName)),
            );
        }
        this.contentsListConfig.emptyListLabel = 'ANY_ITEM';
        this.contentsListConfig.itemSettings = [
            {
                id: 'description',
                prop: 'description',
                header: 'FILE',
                type: ItemType.ADAPTER,
                actionType: ActionType.PREVIEW,
                adapter: StringItemAdapterComponent,
                width: '41%',
                columnPosition: 0,
                sortOrderMobile: 0,
                isMobileBold: true,
            },
            {
                id: 'mimeType',
                prop: 'mimeType',
                header: 'TYPE',
                actionType: ActionType.PREVIEW,
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '12%',
                columnPosition: 1,
            },
            {
                id: 'created_at',
                prop: 'created_at',
                header: 'CREATION_DATE',
                adapter: StringItemAdapterComponent,
                format: DateFormat.DATE,
                type: ItemType.ADAPTER,
                width: '22%',
                actionType: ActionType.PREVIEW,
                columnPosition: 2,
                sortOrderMobile: 1,
                showColNameOnMobile: true,
            },
            {
                id: 'ersteller',
                prop: 'uploadUsername',
                header: 'CREATOR',
                actionType: ActionType.PREVIEW,
                adapter: StringItemAdapterComponent,
                type: ItemType.ADAPTER,
                width: '16%',
                columnPosition: 2,
                sortOrderMobile: 1,
                showColNameOnMobile: true,
            },
        ];

        if (this.hasRightToModified) {
            this.contentsListConfig.itemSettings.push({
                id: 'action_delete',
                prop: 'uuid',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'trash-outline',
                actionType: ActionType.DELETE,
                width: '8%',
                color: 'danger',
                columnPosition: 3,
            });
        }

        this.refreshTable = true;
    }

    async addNewContent(event: string) {
        this.uploader.clearQueue();
        this.fileUploader.nativeElement.click();
        this.uploader.onAfterAddingAll = async (files) => {
            this.refreshTable = false;
            for (const file of files) {
                try {
                    const assetInput = new FormData();
                    assetInput.append('file', file.file.rawFile);
                    this.showLoadingContent = true;
                    let content = await this.exerciseContentService.postContentFileUpload(assetInput);
                    this.showLoadingContent = false;
                    const contentDto = new ContentDto();
                    contentDto.description = file.file.name;
                    contentDto.mimeType = content.mimeType;
                    contentDto.byteSize = content.byteSize;
                    content = await this.exerciseContentService.updateExerciseContent(content.uuid, contentDto);
                    if (this.exerciseId) {
                        await this.exercisesService.assignContentToExercise(this.exerciseId, content.uuid);
                    } else {
                        this.contentOfNewExercise.push(content);
                    }
                    if (!this.contents) {
                        this.contents = [];
                    }
                    this.contents.push(content);
                } catch (e) {
                    this.log.error('Error in addNewContent', e);
                    this.toastService.showToast(
                        `Beim Hochladen der Datei ${file.file.name} ist ein Fehler aufgetreten.`,
                        IonicColor.danger,
                    );
                }
            }
            this.fileUploader.nativeElement.value = '';
            this.uploader.clearQueue();
            this.refreshTable = true;
            await this.initContentsTable();
        };
        this.uploader.onWhenAddingFileFailed = async (item, filter) => {
            switch (filter.name) {
                // The size filter first has to be enabled in the FileUploader options
                case 'fileSize':
                    this.toastService.showToast(
                        `Die Datei ${item.name} ist zu groß. Maximale Dateigröße 20 MB.`,
                        IonicColor.danger,
                    );
                    break;
                case 'mimeType':
                    this.toastService.showToast(
                        `Das Format der Datei ${item.name} wird nicht unterstützt.`,
                        IonicColor.danger,
                    );
                    break;
                // The queue limit first has to be enabled in the FileUploader options
                case 'queueLimit':
                    this.toastService.showToast(
                        `Datei ${item.name} wurde nicht hochgeladen, es kann nur eine Datei gleichzeitig hochgeladen werden`,
                        IonicColor.danger,
                    );
                    break;
                default:
                    this.log.error('Error in addNewContent', `Unknown error (filter is ${filter.name})`);
                    this.toastService.showToast(
                        `Beim Hochladen der Datei ${item.name} ist ein Fehler aufgetreten.`,
                        IonicColor.danger,
                    );
                    break;
            }
        };
    }

    async updateContentsList(value: TableUpdateValue) {
        await this.getExerciseContent(value);
        await this.initContentsTable();
    }

    async addContentFromContentList(event: Event) {
        event.stopPropagation();
        this.refreshTable = false;

        const modal = await this.modalController.create({
            component: ContentsListCheckboxComponent,
            cssClass: 'modal-list',
            componentProps: {
                contents: this.contentsListConfig.list,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data !== null && data) {
            if (data.reload) {
                if (this.exerciseId) {
                    for (const content of data.contents.items) {
                        if (!this.contents.find((item) => item.uuid === content.uuid)) {
                            await this.exercisesService.assignContentToExercise(this.exerciseId, content.uuid);
                            this.contents.push(content);
                        }
                    }
                } else {
                    if (!this.contents) {
                        this.contents = [];
                    }
                    await data.contents.items.map((item) => {
                        this.contents.push(item);
                    });
                    this.contentOfNewExercise = data.contents.items;
                }
                await this.initContentsTable();
            }
        }
        this.refreshTable = true;
    }

    async setActionOfContentTable(actionEmitter: ActionEmitter<Content>) {
        if (actionEmitter.actionType === ActionType.DELETE) {
            this.refreshTable = false;
            if (this.exerciseId && this.contents.find((content) => content.uuid === actionEmitter.item.uuid)) {
                try {
                    await this.exercisesService.deleteContentFromExercise(this.exerciseId, actionEmitter.item.uuid);
                    this.contents = this.contents.filter((content) => content.uuid !== actionEmitter.item.uuid);
                    this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
                } catch (e) {
                    this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                }
            } else {
                const contentExerciseIndex = this.contents.findIndex(
                    (contentExercise) => contentExercise.uuid === actionEmitter.item.uuid,
                );
                this.contents.splice(contentExerciseIndex, 1);
                this.contentOfNewExercise = this.contentsListConfig.list.items;
            }
            await this.initContentsTable();
        } else if (actionEmitter.actionType === ActionType.PREVIEW) {
            await this.openPreview(actionEmitter.item);
        }
    }

    async openPreview(content: Content) {
        await this.exerciseContentService.openAttachmentObjectURLinNewWindow(content, ContentFormatType.ORIGINAL);
    }

    async getExerciseContent(value: TableUpdateValue) {
        try {
            this.contents = await this.exercisesService.getContentsFromExercise(
                this.exerciseId,
                null,
                null,
                value?.sortOrder,
                value?.sortBy as SortBy,
            );

            this.contents = this.contents.filter(
                (i) => i.jsonData?.contentMetaDataType !== ContentMetaDataType.THUMBNAIL,
            );
        } catch (e) {
            this.log.error(e);
            this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
        }
    }
}
