import { Component, OnInit } from '@angular/core';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    ItemType,
    TableConfig,
    TableUpdateValue,
} from '../../../../table/entities/table';
import { ModalController } from '@ionic/angular';
import { ExerciseCommentModalComponent } from '../exercise-comment/exercise-comment-modal.component';
import { FeedbackCommentsService } from '../../../services/feedback-comments';
import { FeedbackComment, FeedbackCommentDto } from '../../../entities/feedback/feedback-comment';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { ModalConfig } from '../../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../../common/entities/modal/modal-button';
import { ModalAlertService } from '../../../../common/services/modal';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { LoadingProcess, LoadingStates } from '../../../../table/components/curafida-table/loading.process';
import { PaginatedResponse } from '../../../../common/entities/paginated-response';
import { ActionButton, ActionItemType } from '../../../../table/entities/action-menu.item';

@Component({
    selector: 'lib-exercise-comments',
    templateUrl: './feedback-comments.component.html',
    styleUrls: ['./feedback-comments.component.scss'],
})
export class FeedbackCommentsComponent implements OnInit {
    protected readonly log: Logger;
    isLoading: LoadingStates = LoadingProcess.initLoadingProcess();
    customerComments: PaginatedResponse<FeedbackComment[]>;
    feedbackCommentTableConfig: TableConfig<FeedbackComment[]> = new TableConfig<FeedbackComment[]>();
    constructor(
        private modalController: ModalController,
        private feedBackCommentService: FeedbackCommentsService,
        private toastService: ToastService,
        private modalAlertService: ModalAlertService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    ngOnInit(): void {
        this.initFeedbackCommentTable();
        this.initCustomerComments({ limit: 10, offset: 0 });
    }

    initFeedbackCommentTable() {
        this.feedbackCommentTableConfig.emptyListLabel = 'TRAINING.COMMENT.ANY_ITEM';
        this.feedbackCommentTableConfig.itemSettings = [
            {
                prop: 'text',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
                columnPosition: 1,
            },
            {
                prop: 'flag',
                header: 'Farbe',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '52%',
                columnPosition: 2,
            },
            {
                prop: '',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                actionType: ActionType.POPOVER,
                fill: 'clear',
                icon: 'ellipsis-vertical',
                id: 'action_open',
                width: '8%',
                columnPosition: 8,
            },
        ];
        this.feedbackCommentTableConfig.isOpenDetailEnable = true;
    }

    initCustomerComments(value: TableUpdateValue) {
        this.feedBackCommentService.getFeedbackComments$(value.offset, value.limit).subscribe({
            next: (customerComments) => {
                this.customerComments = customerComments;
                this.feedbackCommentTableConfig.list = this.customerComments;
                for (const feedbackComment of this.feedbackCommentTableConfig.list.items) {
                    feedbackComment.actions = [];
                    feedbackComment.actions.push(
                        new ActionButton(ActionItemType.BUTTON, 'TRAINING.EXERCISE.DELETE_COMMENT', ActionType.DELETE),
                    );
                }
                this.isLoading = LoadingProcess.finishedSuccessfullyLoadingProcess();
            },
            error: (err) => {
                this.customerComments = PaginatedResponse.init([]);
                this.feedbackCommentTableConfig.list = this.customerComments;
                this.isLoading = LoadingProcess.finishedWithErrorLoadingProcess();
                this.log.error(err);
            },
        });
    }

    async updateComment(isNew?: boolean, comment?: FeedbackCommentDto) {
        const modalTitle = isNew ? 'TRAINING.EXERCISE.NEW_COMMENT' : 'TRAINING.EXERCISE.UPDATE_COMMENT';
        const modal = await this.modalController.create({
            component: ExerciseCommentModalComponent,
            cssClass: 'modal-create-exercise-comment-css',
            componentProps: {
                FeedbackComment: comment,
                title: modalTitle,
            },
        });
        await modal.present();
        await modal.onDidDismiss();
        this.initCustomerComments({ offset: 0, limit: 10 });
    }

    async setActionOnItem(actionEmitter: ActionEmitter<FeedbackComment>) {
        if (actionEmitter.actionType === ActionType.UPDATE) {
            await this.updateComment(false, actionEmitter.item);
        }
        if (actionEmitter.actionType === ActionType.DELETE) {
            await this.removeExerciseComment(actionEmitter.item);
        }
    }

    async openUpdateModal(feedbackComment: FeedbackComment) {
        const item = new ActionEmitter<FeedbackComment>();
        item.item = feedbackComment;
        item.actionType = ActionType.UPDATE;
        await this.setActionOnItem(item);
    }

    async removeExerciseComment(feedbackComment: FeedbackComment) {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = `${feedbackComment.text} löschen`;
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description = `Die Daten werden unwiderruflich gelöscht.
        Wenn Sie den Übungskommentar „${feedbackComment.text}“ löschen möchten, klicken Sie auf „Löschen“.`;

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'Löschen';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            try {
                await this.feedBackCommentService.deleteFeedbackComments(feedbackComment.uuid);
                this.toastService.showToast('Der Kommentar wurde gelöscht', IonicColor.success);
            } catch (err) {
                this.log.error('Error in FeedbackCommentsComponent', err);
                this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            } finally {
                this.initCustomerComments({ offset: 0, limit: 10 });
            }
        }
    }
}
