import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {
    ExerciseSortOrder,
    Therapy,
    TherapyDto,
    TherapyExercise,
    TherapyPatchDto,
    TherapyState,
} from '../../entities/therapy';
import { Exercise } from '../../entities/exercise';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { ApiService } from '../../../api';
import { TherapyGoal } from '../../entities/therapy-goal/therapy-goal';
import { ExerciseType } from '../../entities/exerciseSession';
import { LoadingService } from '../../../common/services/loading/loading.service';

@Injectable({
    providedIn: 'root',
})
export class TherapiesService {
    constructor(
        public router: Router,
        protected http: HttpClient,
        private loadingService: LoadingService,
    ) {}

    async getTherapies(args: {
        offset?: number;
        limit?: number;
        filter?: string;
        sortOrder?: SortOrder;
        therapyState?: TherapyState;
        sortBy?: SortBy;
        therapyGoal?: TherapyGoal;
        exerciseType?: ExerciseType;
    }): Promise<PaginatedResponse<Therapy[]>> {
        const url = new URL(`${ApiService.url}therapies`);
        // build query param string
        if (args.offset) url.searchParams.set('offset', args.offset.toString());
        if (args.limit) url.searchParams.set('limit', args.limit.toString());
        if (args.filter) url.searchParams.set('filter', args.filter);
        if (args.sortBy) url.searchParams.set('sortBy', args.sortBy);
        if (args.sortOrder) url.searchParams.set('sortOrder', args.sortOrder);
        if (args.therapyGoal) url.searchParams.set('therapyGoal', args.therapyGoal);
        if (args.exerciseType) url.searchParams.set('exerciseType', args.exerciseType);
        if (args.therapyState) url.searchParams.set('therapyState', args.therapyState);
        return this.http.get<PaginatedResponse<Therapy[]>>(url.toString()).toPromise();
    }

    async getTherapyId(therapyId: number): Promise<Therapy> {
        const url = `therapies/${therapyId}`;
        return this.http.get<Therapy>(url).toPromise();
    }

    async updateTherapy(therapyId: number, therapy: TherapyDto): Promise<Therapy> {
        this.loadingService.startLoadingModal();
        const url = `therapies/${therapyId}`;
        return this.http
            .put<Therapy>(url, therapy)
            .toPromise()
            .finally(() => {
                this.loadingService.stopLoadingModal();
            });
    }

    patch(therapyId: number, payload: TherapyPatchDto) {
        return this.http.patch<TherapyPatchDto>(`therapies/${therapyId}`, payload);
    }

    async deleteTherapy(therapyId: number) {
        this.loadingService.startLoadingModal();
        const url = `therapies/${therapyId}`;
        return this.http
            .delete(url)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async deleteExerciseOfTherapy(therapyExerciseId: number) {
        this.loadingService.startLoadingModal();
        const url = `therapies/-/therapyExercises/${therapyExerciseId}`;
        return this.http
            .delete(url)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async postExerciseOfTherapy(therapyId: number, exerciseId: number): Promise<TherapyExercise> {
        this.loadingService.startLoadingModal();
        const url = `therapies/${therapyId}/therapyExercises/${exerciseId}`;
        return this.http
            .post<TherapyExercise>(url, '')
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async copyTherapiesFromTemplate(
        therapyTemplateId: number,
        usernames?: string[],
        createEmptySessions?: boolean,
        startDate?: Date,
        endDate?: Date,
        parentId?: number,
    ): Promise<Therapy[]> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}therapies/copyTherapiesFromTemplate/${therapyTemplateId}`);
        if (usernames) {
            for (const username of usernames) {
                url.searchParams.set('usernames', username);
            }
        }
        if (createEmptySessions) url.searchParams.set('createEmptySessions', createEmptySessions.toString());
        if (startDate) url.searchParams.set('startDate', startDate.toISOString());
        if (endDate) url.searchParams.set('endDate', endDate.toISOString());
        if (parentId) url.searchParams.set('parentId', parentId.toString());
        return this.http
            .post<Therapy[]>(url.toString(), null)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async createTherapyFromTemplateForPatient(
        therapyTemplateId: number,
        username: string,
        createExerciseSessions = true,
        startDate?: string,
        endDate?: string,
    ): Promise<Therapy> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}users/${username}/therapies/copyFromTemplate/${therapyTemplateId}`);
        if (!createExerciseSessions) url.searchParams.set('createExerciseSessions', createExerciseSessions.toString());
        if (startDate) url.searchParams.set('startDate', startDate.toString());
        if (endDate) url.searchParams.set('endDate', endDate.toString());
        return this.http
            .post<Therapy>(url.toString(), '')
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async putTherapiesTherapyExercisesOrder(
        exerciseSortOrder: ExerciseSortOrder,
        therapyId: number,
    ): Promise<Exercise> {
        this.loadingService.startLoadingModal();
        const url = `therapies/${therapyId}/therapyExercises/orders`;
        return this.http
            .put<Exercise>(url, exerciseSortOrder)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }
}
