import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ItemType, TableConfig, TableItem } from '../../../../table/entities/table';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { TherapyTemplate } from '../../../entities/therapy/therapy-template';
import { TherapyTemplatesService } from '../../../services/therapy-templates';
import { PaginatedResponse, SortBy, SortOrder } from '../../../../common/entities/paginated-response';
import { ExerciseType } from '../../../entities/exerciseSession';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { CheckBoxItemAdapterComponent } from '../../../../table/components/table-adapter/checkbox-item-adapter.component';
import { CommonComponentsModule } from '../../../../common/components/common-components.module';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { CurafidaTableComponent } from '../../../../table/components/curafida-table/curafida-table.component';
import { LoadingProcess } from '../../../../table/components/curafida-table/loading.process';
import { ToolbarModalComponent } from '../../../../common/components/toolbar-modal/toolbar-modal.component';
import { DefaultSearchParameters, SearchParameters } from '../../../../common/entities/search-parameters';

@Component({
    selector: 'lib-therapy-template-list-modal',
    templateUrl: './therapy-template-list-modal.component.html',
    styleUrls: ['./therapy-template-list-modal.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule, CurafidaTableComponent, ToolbarModalComponent],
})
export class TherapyTemplateListModalComponent implements OnInit {
    title: string;
    selectedTherapyTemplates: TherapyTemplate[] = [];
    currentDefinitionOfTherapyTemplates: TherapyTemplate[] = [];
    therapyTemplateListConfig: TableConfig<TherapyTemplate[]> = new TableConfig();
    isMultipleChoice = false;
    anyItem: string;
    exerciseType = ExerciseType.COURSE;
    tableItems: TableItem[];
    showOldSelectedExercise = false;
    loadingStates = LoadingProcess.initLoadingProcess();
    protected currentSearchParams = DefaultSearchParameters.build({
        limit: 10,
        offset: 0,
        sortBy: SortBy.TITLE,
        sortOrder: SortOrder.ASC,
    });
    private readonly log: Logger;

    constructor(
        private modalCtrl: ModalController,
        private therapyTemplatesService: TherapyTemplatesService,
        private toastService: ToastService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    initTherapyTemplateListConfig() {
        this.therapyTemplateListConfig.isOpenDetailEnable = true;
        this.therapyTemplateListConfig.emptyListLabel = this.anyItem;
        this.therapyTemplateListConfig.itemSettings = [
            {
                id: 'selected',
                prop: 'selected',
                header: '',
                adapter: CheckBoxItemAdapterComponent,
                type: ItemType.ADAPTER,
                width: '10%',
                columnPosition: 0,
            },
            {
                id: 'title',
                prop: 'title',
                header: 'NAME',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
                sortBy: SortBy.TITLE,
                columnPosition: 1,
            },
            {
                id: 'description',
                prop: 'description',
                header: 'DESCRIPTION',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '50%',
                sortBy: SortBy.DESCRIPTION,
                columnPosition: 2,
            },
        ];
    }

    async ngOnInit() {
        if (this.tableItems) this.therapyTemplateListConfig.itemSettings = this.tableItems;
        if (!this.isMultipleChoice && this.selectedTherapyTemplates.length > 1) {
            throw new Error(
                'If the single choice option is set, there cannot be more than one selected exercise as an input.',
            );
        }
        this.initTherapyTemplateListConfig();
        await this.initTherapyTemplateList({ offset: 0 });
    }

    async initTherapyTemplateList(value: SearchParameters) {
        this.currentSearchParams.patch(value);
        this.loadingStates = LoadingProcess.initLoadingProcess();
        this.therapyTemplatesService
            .getTherapyTemplates$({
                offset: value.offset,
                limit: this.currentSearchParams.limit,
                filter: this.currentSearchParams.textSearchTerm,
                sortOrder: this.currentSearchParams.sortOrder,
                sortBy: this.currentSearchParams.sortBy,
                exerciseType: this.exerciseType,
                disabled: false,
            })
            .subscribe({
                next: (paginatedResponse) => {
                    paginatedResponse.items = paginatedResponse.items.map((therapyTemplate) => {
                        therapyTemplate.selected = !!this.selectedTherapyTemplates.find(
                            (t) => t.id === therapyTemplate.id,
                        );
                        return therapyTemplate;
                    });
                    if (this.showOldSelectedExercise === false) {
                        this.therapyTemplateListConfig.list.items = this.therapyTemplateListConfig.list.items.filter(
                            (therapyTemplate) =>
                                !this.selectedTherapyTemplates?.some(
                                    (selectedTemplate) => selectedTemplate?.id === therapyTemplate.id,
                                ),
                        );
                        this.therapyTemplateListConfig.list = PaginatedResponse.init(
                            this.therapyTemplateListConfig.list.items,
                        );
                    }
                    this.currentDefinitionOfTherapyTemplates = [...this.selectedTherapyTemplates];

                    this.loadingStates = LoadingProcess.finishedSuccessfullyLoadingProcess();
                    this.therapyTemplateListConfig.list = paginatedResponse;
                },
                error: (error) => {
                    this.log.error(error);
                    this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                    this.loadingStates = LoadingProcess.finishedWithErrorLoadingProcess();
                    throw error;
                },
            });
    }

    onActionOnItem(clickedTherapyTemplate: TherapyTemplate) {
        clickedTherapyTemplate.selected = !clickedTherapyTemplate.selected;
        if (clickedTherapyTemplate) {
            if (clickedTherapyTemplate.selected) {
                if (!this.isMultipleChoice) {
                    this.currentDefinitionOfTherapyTemplates = [clickedTherapyTemplate];
                    for (const therapyTemplate of this.therapyTemplateListConfig.list.items) {
                        if (clickedTherapyTemplate.id !== therapyTemplate.id) therapyTemplate.selected = false;
                    }
                } else {
                    this.currentDefinitionOfTherapyTemplates.push(clickedTherapyTemplate);
                }
            } else {
                if (!this.isMultipleChoice) {
                    this.currentDefinitionOfTherapyTemplates = [];
                } else {
                    this.currentDefinitionOfTherapyTemplates = this.currentDefinitionOfTherapyTemplates.filter(
                        (e) => e.id !== clickedTherapyTemplate.id,
                    );
                }
            }
        }
    }

    async returnSelectedTherapyTemplate() {
        this.modalCtrl.dismiss(this.currentDefinitionOfTherapyTemplates);
    }

    isReturnButtonDisabled() {
        return (
            this.selectedTherapyTemplates?.every((element) =>
                this.currentDefinitionOfTherapyTemplates.some((template) => template.id === element.id),
            ) &&
            this.currentDefinitionOfTherapyTemplates?.every((element) =>
                this.selectedTherapyTemplates.some((template) => template.id === element.id),
            )
        );
    }
}
